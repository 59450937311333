//Dashboard.jsx
import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { authActions, userActions } from '_store';
import { fetchCargos } from '_store/cargo.slice';
import { fetchTransports } from '_store/transport.slice';
import { deleteCargo } from '_store/cargo.slice';
import { resetAddCargoStatus } from '_store/cargo.slice';
import { AddTransport } from '../addtransport/AddTransport';
import { AddCargo } from '../addcargo/AddCargo';
import { resetAddTransportStatus } from '_store/transport.slice';
import { deleteTransport } from '_store/transport.slice';
import { Pagination } from '_components';
import {CommunicationSettings} from '_components';

import '../index.css';

export { Dashboard };

function Dashboard() {
  const dispatch = useDispatch();
  const { user: authUser } = useSelector(x => x.auth);
  const { users } = useSelector(x => x.users);
  const logout = () => dispatch(authActions.logout());
  const { cargos } = useSelector((x) => x.cargo);
  //const cargoInfo = useSelector((state) => state.cargos.cargoInfo);
  const { transports } = useSelector((x) => x.transport);
  const [selectedTransportRow, setTransportSelectedRow] = useState(null);
  const [selectedCargoRow, setCargoSelectedRow] = useState(null);
  const [showAddTransport, setShowAddTransport] = useState(false);
  const [showAddCargo, setShowAddCargo] = useState(false);
  const [updatedCargoId, setUpdatedCargoId] = useState(null);
  const [updatedTransportId, setUpdatedTransportId] = useState(null);
  const transportSuccess = useSelector((state) => state.transport.transportSuccess);
  const cargoSuccess = useSelector((state) => state.cargo.cargoSuccess);

  //pagination cargo
  const [cargosLength, setCargosLength] = useState(0);
  const [cargostotalPages, setCargosTotalPages] = useState(0);
  const [cargosendIndex, setCargosEndIndex] = useState(0);
  const [cargoscurrentPage, setCargosCurrentPage] = useState(1);
  const cargositemsPerPage = 10; // Set the number of items per page here

  const handleFirst = () => {
    setCargosCurrentPage(1);
    dispatch(fetchCargos({ currentPage: 1, itemsPerPage: cargositemsPerPage }));
  };

  const handlePrev = () => {
    setCargosCurrentPage(cargoscurrentPage - 1);
    dispatch(fetchCargos({ currentPage: cargoscurrentPage - 1, itemsPerPage: cargositemsPerPage }));
  };

  const handleNext = () => {
    setCargosCurrentPage(cargoscurrentPage + 1);
    dispatch(fetchCargos({ currentPage: cargoscurrentPage + 1, itemsPerPage: cargositemsPerPage }));
  };

  const handleLast = () => {
    if (cargostotalPages) {
      setCargosCurrentPage(cargostotalPages);
      dispatch(fetchCargos({ currentPage: cargostotalPages, itemsPerPage: cargositemsPerPage }));
    }
  };

  useEffect(() => {
    if (cargos) {

      const newCargosLength = cargos.total;
      const newTotalPages = Math.ceil(newCargosLength / cargositemsPerPage);
      const newstartIndex = (cargoscurrentPage - 1) * cargositemsPerPage;
      const newEndIndex = Math.min(newstartIndex + cargositemsPerPage, newCargosLength);
      //const visibleCargos = cargos.slice(startIndex, endIndex);

      setCargosLength(newCargosLength);
      setCargosTotalPages(newTotalPages);
      setCargosEndIndex(newEndIndex);
    }
  }, [cargos]);

  //end pagination cargo

  //pagination transport
  const [transportsLength, setTransportsLength] = useState(0);
  const [transportstotalPages, setTransportsTotalPages] = useState(0);
  const [transportsendIndex, setTransportsEndIndex] = useState(0);
  const [transportscurrentPage, setTransportsCurrentPage] = useState(1);
  const transportsitemsPerPage = 10; // Set the number of items per page here

  const handleFirstTransport = () => {
    setTransportsCurrentPage(1);
    dispatch(fetchTransports({ currentPage: 1, itemsPerPage: transportsitemsPerPage }));
  };

  const handlePrevTransport = () => {
    setTransportsCurrentPage(transportscurrentPage - 1);
    dispatch(fetchTransports({ currentPage: transportscurrentPage - 1, itemsPerPage: transportsitemsPerPage }));
  };

  const handleNextTransport = () => {
    setTransportsCurrentPage(transportscurrentPage + 1);
    dispatch(fetchTransports({ currentPage: transportscurrentPage + 1, itemsPerPage: transportsitemsPerPage }));
  };

  const handleLastTransport = () => {
    if (transportstotalPages) {
      setTransportsCurrentPage(transportstotalPages);
      dispatch(fetchTransports({ currentPage: transportstotalPages, itemsPerPage: transportsitemsPerPage }));
    }
  };

  useEffect(() => {
    if (transports) {

      //console.log("!!! transports: "+JSON.stringify(transports));

      const newTransportsLength = transports.total;
      const newTotalPages = Math.ceil(newTransportsLength / transportsitemsPerPage);
      const newstartIndex = (transportscurrentPage - 1) * transportsitemsPerPage;
      const newEndIndex = Math.min(newstartIndex + transportsitemsPerPage, newTransportsLength);
      //const visibleTransports = transports.slice(startIndex, endIndex);

      setTransportsLength(newTransportsLength);
      setTransportsTotalPages(newTotalPages);
      setTransportsEndIndex(newEndIndex);
    }
  }, [transports]);

  //end pagination transport


  function formatDate(dateString) {
    const date = new Date(dateString);
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear().toString().slice(2);
    const hours = date.getHours().toString().padStart(2, '0');
    const minutes = date.getMinutes().toString().padStart(2, '0');
    return `${day}.${month}.${year} - ${hours}:${minutes}`;
  }

  const handleTransportRowClick = (id) => {
    setTransportSelectedRow(id);
  };

  const handleCargoRowClick = (id) => {
    setCargoSelectedRow(id);
  };

  function hideCargoRow(id) {
    /*const cargoTable = document.querySelector('.cargoTable');
    if (cargoTable) {

      const row = cargoTable.querySelector(`tr[id='c_${id}']`);

      if (row) {
        row.classList.add('hidden');
        setTimeout(() => {
          row.style.display = 'none';
        }, 8000);
      }
    }*/
  }


  function hideTransportRow(id) {
    /*const transportTable = document.querySelector('.transportTable');
    if (transportTable) {

      const row = transportTable.querySelector(`tr[id='t_${id}']`);

      if (row) {
        row.classList.add('hidden');
        setTimeout(() => {
          row.style.display = 'none';
        }, 8000);
      }
    }*/
  }

  const handleDeleteTransport = (transportId) => {
    hideTransportRow(transportId);
    dispatch(deleteTransport(transportId))
      .unwrap()
      .then(() => {

        dispatch(resetAddTransportStatus());//clear status

      })
      .catch((error) => {
      });
  };

  const handleDeleteCargo = (cargoId) => {
    hideCargoRow(cargoId);
    dispatch(deleteCargo(cargoId))
      .unwrap()
      .then(() => {

        dispatch(resetAddCargoStatus());//clear status

      })
      .catch((error) => {
      });
  };

  const handleUpdateCargo = (id) => {
    setUpdatedCargoId(id);
    setShowAddCargo(true);
  };

  const handleUpdateTransport = (id) => {
    setUpdatedTransportId(id);
    setShowAddTransport(true);
  };

  const closeUpdateCargo = () => {
    setShowAddCargo(false);
  };

  const closeUpdateTransport = () => {
    setShowAddTransport(false);
  };

  const handleCargoDelete = (id) => {
    handleDeleteCargo(id);
  };

  const handleTransportDelete = (id) => {
    handleDeleteTransport(id);
  };

  useEffect(() => {
    if (transportSuccess) {
      dispatch(fetchTransports({ currentPage: transportscurrentPage, itemsPerPage: transportsitemsPerPage }));
    }
  }, [transportSuccess]);

  useEffect(() => {
    if (cargoSuccess) {
      dispatch(fetchCargos({ currentPage: cargoscurrentPage, itemsPerPage: cargositemsPerPage }));
    }
  }, [cargoSuccess]);

  useEffect(() => {

    dispatch(userActions.getUserData())
      .then((result) => {
        if (result.error && result.error.message === 'user not found') {
          logout();
        }
      })
      .catch((error) => {

      });

    dispatch(fetchCargos({ currentPage: cargoscurrentPage, itemsPerPage: cargositemsPerPage }));
    dispatch(fetchTransports({ currentPage: transportscurrentPage, itemsPerPage: transportsitemsPerPage }));

  }, [dispatch]);

  return (
    <div className="container-fluid">

      {/* Add the overlay div blur */}
      <div className="overlay-blur">
        {/* Add the red inner div */}
        <div className="ob-inner-div">
          This is a red div inside a blurred overlay.
        </div>
      </div>

      {showAddTransport && (
        <div className="overlay">
          <div className="inner-div">
            <AddTransport isUpdate={true} closeUpdateTransport={closeUpdateTransport} transportId={updatedTransportId} />
          </div>
        </div>)}

      {showAddCargo && (
        <div className="overlay">
          <div className="inner-div">
            <AddCargo isUpdate={true} closeUpdateCargo={closeUpdateCargo} cargoId={updatedCargoId} />
          </div>
        </div>)}

      <div className="row">
        <div className="card fullwidth tpt">
          <h4 className="card-header"><svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" className="bi bi-person isonHeader" viewBox="0 0 16 16">
            <path d="M8 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6Zm2-3a2 2 0 1 1-4 0 2 2 0 0 1 4 0Zm4 8c0 1-1 1-1 1H3s-1 0-1-1 1-4 6-4 6 3 6 4Zm-1-.004c-.001-.246-.154-.986-.832-1.664C11.516 10.68 10.289 10 8 10c-2.29 0-3.516.68-4.168 1.332-.678.678-.83 1.418-.832 1.664h10Z" />
          </svg>&nbsp;Dashboard</h4>
          <div className="card-body">
            <div>
              {users ? (
                <div>
                  <h5><strong>User: {users.userName}</strong></h5><br/>
                   <CommunicationSettings />
                </div>
              ) : (
                <p>No users available.</p>
              )}
              {users.loading && <div className="spinner-border spinner-border-sm"></div>}
              {users.error && <div className="text-danger">Error loading users: {users.error.message}</div>}
            </div>
          </div>
        </div>

        {/*List of cargo*/}
        <div className="card fullwidth marginTB tpt">
          <h4 className="card-header cargoColor"><svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" className="bi bi-box-seam isonHeader" viewBox="0 0 16 16">
            <path d="M8.186 1.113a.5.5 0 0 0-.372 0L1.846 3.5l2.404.961L10.404 2l-2.218-.887zm3.564 1.426L5.596 5 8 5.961 14.154 3.5l-2.404-.961zm3.25 1.7-6.5 2.6v7.922l6.5-2.6V4.24zM7.5 14.762V6.838L1 4.239v7.923l6.5 2.6zM7.443.184a1.5 1.5 0 0 1 1.114 0l7.129 2.852A.5.5 0 0 1 16 3.5v8.662a1 1 0 0 1-.629.928l-7.185 2.874a.5.5 0 0 1-.372 0L.63 13.09a1 1 0 0 1-.63-.928V3.5a.5.5 0 0 1 .314-.464L7.443.184z" />
          </svg>&nbsp;My Cargo</h4>
          <div className="card-body overflow-body">
            <div>
              <Pagination
                currentPage={cargoscurrentPage}
                totalPages={cargostotalPages}
                handleFirst={handleFirst}
                handlePrev={handlePrev}
                handleNext={handleNext}
                handleLast={handleLast}
              />
            </div>
            {cargos && cargos.cargoList && cargos.cargoList.length > 0 ? (
              <table className="table cargoTable">
                <thead>
                  <tr>
                    <th>id</th>
                    <th>Created</th>
                    <th className="wrap-content">Name</th>
                    <th >Price</th>
                    <th>Weight</th>
                    <th>Volume</th>
                    <th>Loading</th>
                    <th >Unloading</th>
                    <th className="wrap-content">Notes</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {cargos.cargoList.map((cargo) => (
                    <tr id={'c_' + cargo.id} key={cargo.id}
                      className={selectedCargoRow === cargo.id ? 'selected' : ''}
                      onClick={() => handleCargoRowClick(cargo.id)}>

                      <td><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-box-seam isonInTable" viewBox="0 0 16 16">
                        <path d="M8.186 1.113a.5.5 0 0 0-.372 0L1.846 3.5l2.404.961L10.404 2l-2.218-.887zm3.564 1.426L5.596 5 8 5.961 14.154 3.5l-2.404-.961zm3.25 1.7-6.5 2.6v7.922l6.5-2.6V4.24zM7.5 14.762V6.838L1 4.239v7.923l6.5 2.6zM7.443.184a1.5 1.5 0 0 1 1.114 0l7.129 2.852A.5.5 0 0 1 16 3.5v8.662a1 1 0 0 1-.629.928l-7.185 2.874a.5.5 0 0 1-.372 0L.63 13.09a1 1 0 0 1-.63-.928V3.5a.5.5 0 0 1 .314-.464L7.443.184z" />
                      </svg>&nbsp;{cargo.id}</td>

                      <td>{formatDate(cargo.created_at)}</td>

                      <td className="wrap-content">{cargo.name}</td>
                      <td className="price-column">{cargo.price}<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-currency-euro isonInTable" viewBox="0 0 16 16">
                        <path d="M4 9.42h1.063C5.4 12.323 7.317 14 10.34 14c.622 0 1.167-.068 1.659-.185v-1.3c-.484.119-1.045.17-1.659.17-2.1 0-3.455-1.198-3.775-3.264h4.017v-.928H6.497v-.936c0-.11 0-.219.008-.329h4.078v-.927H6.618c.388-1.898 1.719-2.985 3.723-2.985.614 0 1.175.05 1.659.177V2.194A6.617 6.617 0 0 0 10.341 2c-2.928 0-4.82 1.569-5.244 4.3H4v.928h1.01v1.265H4v.928z" />
                      </svg></td>

                      <td>{cargo.weight}</td>
                      <td>{cargo.volume}</td>

                      <td className='point-column'>{cargo.loading_place}&nbsp;<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-geo-alt isonInTable" viewBox="0 0 16 16">
                        <path d="M12.166 8.94c-.524 1.062-1.234 2.12-1.96 3.07A31.493 31.493 0 0 1 8 14.58a31.481 31.481 0 0 1-2.206-2.57c-.726-.95-1.436-2.008-1.96-3.07C3.304 7.867 3 6.862 3 6a5 5 0 0 1 10 0c0 .862-.305 1.867-.834 2.94zM8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10z" />
                        <path d="M8 8a2 2 0 1 1 0-4 2 2 0 0 1 0 4zm0 1a3 3 0 1 0 0-6 3 3 0 0 0 0 6z" />
                      </svg></td>
                      <td className='point-column'>{cargo.unloading_place}&nbsp;<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-geo-alt isonInTable" viewBox="0 0 16 16">
                        <path d="M12.166 8.94c-.524 1.062-1.234 2.12-1.96 3.07A31.493 31.493 0 0 1 8 14.58a31.481 31.481 0 0 1-2.206-2.57c-.726-.95-1.436-2.008-1.96-3.07C3.304 7.867 3 6.862 3 6a5 5 0 0 1 10 0c0 .862-.305 1.867-.834 2.94zM8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10z" />
                        <path d="M8 8a2 2 0 1 1 0-4 2 2 0 0 1 0 4zm0 1a3 3 0 1 0 0-6 3 3 0 0 0 0 6z" />
                      </svg></td>

                      <td className="wrap-content">
                        {cargo.additional_notes}</td>
                      <td>
                        <button className="round-button"
                          onClick={() => handleUpdateCargo(cargo.id)}><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-pen" viewBox="0 0 16 16">
                            <path d="m13.498.795.149-.149a1.207 1.207 0 1 1 1.707 1.708l-.149.148a1.5 1.5 0 0 1-.059 2.059L4.854 14.854a.5.5 0 0 1-.233.131l-4 1a.5.5 0 0 1-.606-.606l1-4a.5.5 0 0 1 .131-.232l9.642-9.642a.5.5 0 0 0-.642.056L6.854 4.854a.5.5 0 1 1-.708-.708L9.44.854A1.5 1.5 0 0 1 11.5.796a1.5 1.5 0 0 1 1.998-.001zm-.644.766a.5.5 0 0 0-.707 0L1.95 11.756l-.764 3.057 3.057-.764L14.44 3.854a.5.5 0 0 0 0-.708l-1.585-1.585z" />
                          </svg></button>
                        <button className="round-button delete"
                          onClick={() => handleCargoDelete(cargo.id)}><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-trash" viewBox="0 0 16 16">
                            <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5Zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5Zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6Z" />
                            <path d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1ZM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118ZM2.5 3h11V2h-11v1Z" />
                          </svg></button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            ) : (
              <p>No cargos available.</p>
            )}

            {/* Mobile cargo list*/}
            {cargos && cargos.cargoList && cargos.cargoList.length > 0 ? (
              <ul className="mobile-list">
                {cargos.cargoList.map((cargo) => (
                  <li key={cargo.id}
                   className={selectedCargoRow === cargo.id ? 'selected' : ''}
                    onClick={() => handleCargoRowClick(cargo.id)}>
                      <div>
                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-box-seam isonInTable" viewBox="0 0 16 16">
                        <path d="M8.186 1.113a.5.5 0 0 0-.372 0L1.846 3.5l2.404.961L10.404 2l-2.218-.887zm3.564 1.426L5.596 5 8 5.961 14.154 3.5l-2.404-.961zm3.25 1.7-6.5 2.6v7.922l6.5-2.6V4.24zM7.5 14.762V6.838L1 4.239v7.923l6.5 2.6zM7.443.184a1.5 1.5 0 0 1 1.114 0l7.129 2.852A.5.5 0 0 1 16 3.5v8.662a1 1 0 0 1-.629.928l-7.185 2.874a.5.5 0 0 1-.372 0L.63 13.09a1 1 0 0 1-.63-.928V3.5a.5.5 0 0 1 .314-.464L7.443.184z" />
                      </svg>&nbsp;
                      <strong>id: {cargo.id}</strong>
                      </div>
                      <div><strong>Created: {formatDate(cargo.created_at)}</strong></div>
                      <div><strong>Type of cargo: {cargo.name}</strong></div>
                      <div className='price-column'><strong>Price: {cargo.price} €</strong></div>
                      <div><strong>Weight: {cargo.weight} t</strong></div>
                      <div><strong>Volume: {cargo.volume} m&sup3;</strong></div>

                      <div className="point-column"><strong>Loading place: {cargo.loading_place}{' '}</strong>
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-geo-alt isonInTable" viewBox="0 0 16 16">
                        <path d="M12.166 8.94c-.524 1.062-1.234 2.12-1.96 3.07A31.493 31.493 0 0 1 8 14.58a31.481 31.481 0 0 1-2.206-2.57c-.726-.95-1.436-2.008-1.96-3.07C3.304 7.867 3 6.862 3 6a5 5 0 0 1 10 0c0 .862-.305 1.867-.834 2.94zM8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10z" />
                        <path d="M8 8a2 2 0 1 1 0-4 2 2 0 0 1 0 4zm0 1a3 3 0 1 0 0-6 3 3 0 0 0 0 6z" />
                      </svg>
                      </div>
                      <div className="point-column"><strong>Unloading place: {cargo.unloading_place}{' '}</strong>
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-geo-alt isonInTable" viewBox="0 0 16 16">
                        <path d="M12.166 8.94c-.524 1.062-1.234 2.12-1.96 3.07A31.493 31.493 0 0 1 8 14.58a31.481 31.481 0 0 1-2.206-2.57c-.726-.95-1.436-2.008-1.96-3.07C3.304 7.867 3 6.862 3 6a5 5 0 0 1 10 0c0 .862-.305 1.867-.834 2.94zM8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10z" />
                        <path d="M8 8a2 2 0 1 1 0-4 2 2 0 0 1 0 4zm0 1a3 3 0 1 0 0-6 3 3 0 0 0 0 6z" />
                      </svg>
                      </div>
                      <div className="cargo-notes"><strong>Notes: {cargo.additional_notes}</strong></div>
                     
                    <div className="cargo-actions">
                      <button className="round-button" onClick={() => handleUpdateCargo(cargo.id)}>
                      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" className="bi bi-pen" viewBox="0 0 16 16">
                          <path d="m13.498.795.149-.149a1.207 1.207 0 1 1 1.707 1.708l-.149.148a1.5 1.5 0 0 1-.059 2.059L4.854 14.854a.5.5 0 0 1-.233.131l-4 1a.5.5 0 0 1-.606-.606l1-4a.5.5 0 0 1 .131-.232l9.642-9.642a.5.5 0 0 0-.642.056L6.854 4.854a.5.5 0 1 1-.708-.708L9.44.854A1.5 1.5 0 0 1 11.5.796a1.5 1.5 0 0 1 1.998-.001zm-.644.766a.5.5 0 0 0-.707 0L1.95 11.756l-.764 3.057 3.057-.764L14.44 3.854a.5.5 0 0 0 0-.708l-1.585-1.585z" />
                        </svg>
                      </button>
                      <button className="round-button delete" onClick={() => handleCargoDelete(cargo.id)}>
                      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" className="bi bi-trash" viewBox="0 0 16 16">
                          <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5Zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5Zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6Z" />
                          <path d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1ZM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118ZM2.5 3h11V2h-11v1Z" />
                        </svg>
                      </button>
                    </div>
                  </li>
                ))}
              </ul>
            ) : (
              <p></p>
            )}
            {/* end mobile cargo list*/}

            {cargos && cargos.loading && <div className="spinner-border spinner-border-sm"></div>}
            {cargos && cargos.error && (
              <div className="text-danger">Error loading cargos: {cargos.error.message}</div>
            )}
          </div>
        </div>
        {/*end list of cargo*/}

        <div className="card fullwidth marginTB tpt">
          <h4 className="card-header transportColor"><svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" className="bi bi-bus-front-fill" viewBox="0 0 16 16">
            <path d="M16 7a1 1 0 0 1-1 1v3.5c0 .818-.393 1.544-1 2v2a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1-.5-.5V14H5v1.5a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1-.5-.5v-2a2.496 2.496 0 0 1-1-2V8a1 1 0 0 1-1-1V5a1 1 0 0 1 1-1V2.64C1 1.452 1.845.408 3.064.268A43.608 43.608 0 0 1 8 0c2.1 0 3.792.136 4.936.268C14.155.408 15 1.452 15 2.64V4a1 1 0 0 1 1 1v2ZM3.552 3.22A43.306 43.306 0 0 1 8 3c1.837 0 3.353.107 4.448.22a.5.5 0 0 0 .104-.994A44.304 44.304 0 0 0 8 2c-1.876 0-3.426.109-4.552.226a.5.5 0 1 0 .104.994ZM8 4c-1.876 0-3.426.109-4.552.226A.5.5 0 0 0 3 4.723v3.554a.5.5 0 0 0 .448.497C4.574 8.891 6.124 9 8 9c1.876 0 3.426-.109 4.552-.226A.5.5 0 0 0 13 8.277V4.723a.5.5 0 0 0-.448-.497A44.304 44.304 0 0 0 8 4Zm-3 7a1 1 0 1 0-2 0 1 1 0 0 0 2 0Zm8 0a1 1 0 1 0-2 0 1 1 0 0 0 2 0Zm-7 0a1 1 0 0 0 1 1h2a1 1 0 1 0 0-2H7a1 1 0 0 0-1 1Z" />
          </svg>&nbsp;My Transport</h4>
          <div className="card-body overflow-body">
            <div>
              <Pagination
                currentPage={transportscurrentPage}
                totalPages={transportstotalPages}
                handleFirst={handleFirstTransport}
                handlePrev={handlePrevTransport}
                handleNext={handleNextTransport}
                handleLast={handleLastTransport}
              />
            </div>
            {transports && transports.transportList && transports.transportList.length > 0 ? (
              <table className="table transportTable">
                <thead>
                  <tr>
                    <th>id</th>
                    <th>Created</th>
                    <th className="wrap-content">Vehicle Type</th>
                    <th>Capacity</th>
                    <th>Volume</th>
                    <th >Current location</th>
                    <th className="wrap-content">Notes</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {transports.transportList.map((transport) => (
                    <tr id={'t_' + transport.id} key={transport.id}
                      className={selectedTransportRow === transport.id ? 'selected' : ''}
                      onClick={() => handleTransportRowClick(transport.id)}>

                      <td><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-bus-front-fill isonInTable" viewBox="0 0 16 16">
                        <path d="M16 7a1 1 0 0 1-1 1v3.5c0 .818-.393 1.544-1 2v2a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1-.5-.5V14H5v1.5a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1-.5-.5v-2a2.496 2.496 0 0 1-1-2V8a1 1 0 0 1-1-1V5a1 1 0 0 1 1-1V2.64C1 1.452 1.845.408 3.064.268A43.608 43.608 0 0 1 8 0c2.1 0 3.792.136 4.936.268C14.155.408 15 1.452 15 2.64V4a1 1 0 0 1 1 1v2ZM3.552 3.22A43.306 43.306 0 0 1 8 3c1.837 0 3.353.107 4.448.22a.5.5 0 0 0 .104-.994A44.304 44.304 0 0 0 8 2c-1.876 0-3.426.109-4.552.226a.5.5 0 1 0 .104.994ZM8 4c-1.876 0-3.426.109-4.552.226A.5.5 0 0 0 3 4.723v3.554a.5.5 0 0 0 .448.497C4.574 8.891 6.124 9 8 9c1.876 0 3.426-.109 4.552-.226A.5.5 0 0 0 13 8.277V4.723a.5.5 0 0 0-.448-.497A44.304 44.304 0 0 0 8 4Zm-3 7a1 1 0 1 0-2 0 1 1 0 0 0 2 0Zm8 0a1 1 0 1 0-2 0 1 1 0 0 0 2 0Zm-7 0a1 1 0 0 0 1 1h2a1 1 0 1 0 0-2H7a1 1 0 0 0-1 1Z" />
                      </svg>&nbsp;{transport.id}</td>

                      <td>{formatDate(transport.created_at)}</td>

                      <td className="wrap-content">{transport.vehicle_type}</td>

                      <td>{transport.capacity}</td>
                      <td>{transport.volume}</td>

                      <td className='point-column'>{transport.current_location}&nbsp;<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-geo-alt isonInTable" viewBox="0 0 16 16">
                        <path d="M12.166 8.94c-.524 1.062-1.234 2.12-1.96 3.07A31.493 31.493 0 0 1 8 14.58a31.481 31.481 0 0 1-2.206-2.57c-.726-.95-1.436-2.008-1.96-3.07C3.304 7.867 3 6.862 3 6a5 5 0 0 1 10 0c0 .862-.305 1.867-.834 2.94zM8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10z" />
                        <path d="M8 8a2 2 0 1 1 0-4 2 2 0 0 1 0 4zm0 1a3 3 0 1 0 0-6 3 3 0 0 0 0 6z" />
                      </svg></td>

                      <td className="wrap-content">

                        {transport.comments}</td>
                      <td>
                        <button className="round-button"
                          onClick={() => handleUpdateTransport(transport.id)}><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-pen" viewBox="0 0 16 16">
                            <path d="m13.498.795.149-.149a1.207 1.207 0 1 1 1.707 1.708l-.149.148a1.5 1.5 0 0 1-.059 2.059L4.854 14.854a.5.5 0 0 1-.233.131l-4 1a.5.5 0 0 1-.606-.606l1-4a.5.5 0 0 1 .131-.232l9.642-9.642a.5.5 0 0 0-.642.056L6.854 4.854a.5.5 0 1 1-.708-.708L9.44.854A1.5 1.5 0 0 1 11.5.796a1.5 1.5 0 0 1 1.998-.001zm-.644.766a.5.5 0 0 0-.707 0L1.95 11.756l-.764 3.057 3.057-.764L14.44 3.854a.5.5 0 0 0 0-.708l-1.585-1.585z" />
                          </svg></button>
                        <button className="round-button delete"
                          onClick={() => handleTransportDelete(transport.id)}><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-trash" viewBox="0 0 16 16">
                            <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5Zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5Zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6Z" />
                            <path d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1ZM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118ZM2.5 3h11V2h-11v1Z" />
                          </svg></button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            ) : (
              <p>No transports available.</p>
            )}

            {/* Mobile Transport list */}
            {transports && transports.transportList && transports.transportList.length > 0 ? (
              <ul className="mobile-list">
                {transports.transportList.map((transport) => (
                  <li
                    key={transport.id}
                    className={selectedTransportRow === transport.id ? 'selected' : ''}
                    onClick={() => handleTransportRowClick(transport.id)}>
                    <div>
                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-bus-front-fill isonInTable" viewBox="0 0 16 16">
                        <path d="M16 7a1 1 0 0 1-1 1v3.5c0 .818-.393 1.544-1 2v2a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1-.5-.5V14H5v1.5a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1-.5-.5v-2a2.496 2.496 0 0 1-1-2V8a1 1 0 0 1-1-1V5a1 1 0 0 1 1-1V2.64C1 1.452 1.845.408 3.064.268A43.608 43.608 0 0 1 8 0c2.1 0 3.792.136 4.936.268C14.155.408 15 1.452 15 2.64V4a1 1 0 0 1 1 1v2ZM3.552 3.22A43.306 43.306 0 0 1 8 3c1.837 0 3.353.107 4.448.22a.5.5 0 0 0 .104-.994A44.304 44.304 0 0 0 8 2c-1.876 0-3.426.109-4.552.226a.5.5 0 1 0 .104.994ZM8 4c-1.876 0-3.426.109-4.552.226A.5.5 0 0 0 3 4.723v3.554a.5.5 0 0 0 .448.497C4.574 8.891 6.124 9 8 9c1.876 0 3.426-.109 4.552-.226A.5.5 0 0 0 13 8.277V4.723a.5.5 0 0 0-.448-.497A44.304 44.304 0 0 0 8 4Zm-3 7a1 1 0 1 0-2 0 1 1 0 0 0 2 0Zm8 0a1 1 0 1 0-2 0 1 1 0 0 0 2 0Zm-7 0a1 1 0 0 0 1 1h2a1 1 0 1 0 0-2H7a1 1 0 0 0-1 1Z" />
                      </svg>&nbsp;
                      <strong>id: {transport.id}</strong>
                    </div>
                    <div>
                      <strong>Created: {formatDate(transport.created_at)}</strong>
                    </div>
                    <div >
                      <strong>Vehicle Type: {transport.vehicle_type}</strong>
                    </div>
                    <div>
                      <strong>Capacity: {transport.capacity} t</strong>
                    </div>
                    <div>
                      <strong>Volume: {transport.volume} m&sup3;</strong>
                    </div>
                    <div className='point-column'>
                      <strong>Current location: {transport.current_location}{' '}</strong>
                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-geo-alt isonInTable" viewBox="0 0 16 16">
                        <path d="M12.166 8.94c-.524 1.062-1.234 2.12-1.96 3.07A31.493 31.493 0 0 1 8 14.58a31.481 31.481 0 0 1-2.206-2.57c-.726-.95-1.436-2.008-1.96-3.07C3.304 7.867 3 6.862 3 6a5 5 0 0 1 10 0c0 .862-.305 1.867-.834 2.94zM8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10z" />
                        <path d="M8 8a2 2 0 1 1 0-4 2 2 0 0 1 0 4zm0 1a3 3 0 1 0 0-6 3 3 0 0 0 0 6z" />
                      </svg>
                    </div>
                    <div >
                      <strong>Notes: {transport.comments}</strong>
                    </div>
                    <div>
                      <button className="round-button" onClick={() => handleUpdateTransport(transport.id)}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" className="bi bi-pen" viewBox="0 0 16 16">
                          <path d="m13.498.795.149-.149a1.207 1.207 0 1 1 1.707 1.708l-.149.148a1.5 1.5 0 0 1-.059 2.059L4.854 14.854a.5.5 0 0 1-.233.131l-4 1a.5.5 0 0 1-.606-.606l1-4a.5.5 0 0 1 .131-.232l9.642-9.642a.5.5 0 0 0-.642.056L6.854 4.854a.5.5 0 1 1-.708-.708L9.44.854A1.5 1.5 0 0 1 11.5.796a1.5 1.5 0 0 1 1.998-.001zm-.644.766a.5.5 0 0 0-.707 0L1.95 11.756l-.764 3.057 3.057-.764L14.44 3.854a.5.5 0 0 0 0-.708l-1.585-1.585z" />
                        </svg>
                      </button>
                      <button className="round-button delete" onClick={() => handleTransportDelete(transport.id)}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" className="bi bi-trash" viewBox="0 0 16 16">
                          <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5Zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5Zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6Z" />
                          <path d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1ZM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118ZM2.5 3h11V2h-11v1Z" />
                        </svg>
                      </button>
                    </div>
                  </li>
                ))}
              </ul>
            ) : (
              <p></p>
            )}
            {/*end mobile transport list */}

            {transports && transports.loading && <div className="spinner-border spinner-border-sm"></div>}
            {transports && transports.error && (
              <div className="text-danger">Error loading transports: {transports.error.message}</div>
            )}
          </div>
        </div>

      </div>
    </div>
  );
}
