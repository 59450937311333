import React, { useState, useEffect } from 'react';
import { fetchContactInfo, resetAddPersoneStatus } from '_store/persone.slice';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';

export { ContactInfo };

const ContactInfo = ({ closeContactInfo, entityType, selectedId }) => {

    const dispatch = useDispatch();
    const { reset, register, formState: { errors } } = useForm();
    const personeData = useSelector((state) => state.persone.personeData);

    useEffect(() => {
        if (entityType === "cargo") {
            if (selectedId != 0) {
                dispatch(fetchContactInfo({ entityType: entityType, selectedId: selectedId }))
                    .then((result) => {

                    })
                    .catch((error) => {

                    });
            }
        } else if (entityType === "transport") {
            if (selectedId != 0) {
                console.log("!!! transport selectedId: "+selectedId);
                dispatch(fetchContactInfo({ entityType: entityType, selectedId: selectedId }))
                    .then((result) => {

                    })
                    .catch((error) => {

                    });
            }
        }
    }, [dispatch, entityType,selectedId]);

    useEffect(() => {
        if (personeData) {
            reset({
                name: personeData.name,
                cell1: personeData.cell1,
                cell2: personeData.cell2,
                whatsapp: personeData.whatsApp,
                skype: personeData.skype,
                viber: personeData.viber,
                telegram: personeData.telegram
            });
        } else {
            reset({
                name: '',
                cell1: '',
                cell2: '',
                whatsapp: '',
                skype: '',
                viber: '',
                telegram: ''
            });
        }
    }, [personeData]);
    

    const copyToClipboard = (text) => {
        navigator.clipboard.writeText(text).then(() => {
        });
    };

    return (
        <div className='contactInfo'>
            <h5>Contact Info:</h5>
            <form>
                <div className="">
                    {Object.entries({
                        name: "Name",
                        cell1: "Cell1",
                        cell2: "Cell2",
                        whatsapp: "WhatsApp",
                        skype: "Skype",
                        viber: "Viber",
                        telegram: "Telegram",
                    }).map(([fieldName, label]) => (
                        <div key={fieldName} className="">
                            <div className="form-group cifg">
                                <div className="cilabel">{label}:</div>
                                <input
                                    type="text"
                                    name={fieldName}
                                    {...register(fieldName)}
                                    readOnly
                                    className={`form-control ${errors[fieldName] ? 'is-invalid' : ''}`}
                                />
                                <button
                                    type="button"
                                    onClick={() => copyToClipboard(personeData[fieldName])}
                                    className="btn btn-secondary"
                                >
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-copy" viewBox="0 0 16 16">
                                        <path fillRule="evenodd" d="M4 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2V2Zm2-1a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H6ZM2 5a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1v-1h1v1a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h1v1H2Z" />
                                    </svg>
                                </button>
                            </div>
                        </div>
                    ))}
                </div>

                <div className="form-group">
                    <button type='button' onClick={() => closeContactInfo()} className="btn btn-secondary">
                        Close
                    </button>
                </div>
            </form>
        </div>
    );
};
