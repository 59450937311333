// AddTransport.jsx
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { addTransport, updateTransport } from '_store/transport.slice';
import { resetAddTransportStatus } from '_store/transport.slice';
import { CommunicationSettingsModal } from '_components';
import { LocationPicker } from '_components';
import { fetchTransportById } from '_store/transport.slice';
import {fetchCommunicationSettings} from '_store/persone.slice';
import '../index.css';

export function AddTransport({ isUpdate, closeUpdateTransport, transportId }) {
  const dispatch = useDispatch();
  const transportError = useSelector((state) => state.transport.transportError);
  const transportSuccess = useSelector((state) => state.transport.transportSuccess);
  const [selectedCoordinates, setSelectedCoordinates] = useState(null);
  const navigate = useNavigate();
  const [isMapModalOpen, setIsMapModalOpen] = useState(false);
  const transportData = useSelector((state) => state.transport.transportData);
  const [showCommunicationSettings, setShowCommunicationSettings] = useState(false);
  const personeError = useSelector((state) => state.persone.personeError);
  const personeData = useSelector((state) => state.persone.personeData);
  const adding = useSelector((state)=>state.transport.adding);
  const [isInProgress, setisInProgress] = useState(false);

  useEffect(() => {
    if (adding) {
      setisInProgress(true);
    }
   else{
    setisInProgress(false);
   }
  }, [adding]);

  const handleCancelClick = () => {
    closeUpdateTransport();
  };

  const handleLocationSelected = (coordinates) => {
    setSelectedCoordinates(coordinates);
    setLocationError('');
  };

  const validationSchema = Yup.object().shape({
    vehicle_type: Yup.string()
      .max(100, 'Type of transport should not exceed 100 characters')
      .required('Type of transport is required'),
    comments: Yup.string()
      .max(250, 'Additional notes should not exceed 250 characters'),
  });

  const formOptions = { resolver: yupResolver(validationSchema) };
  const { register, handleSubmit, formState, reset } = useForm(formOptions);
  const { errors, isSubmitting } = formState;
  const [locationError, setLocationError] = useState('');
  const location = useLocation();

  useEffect(() => {
    if (!personeData) {
      dispatch(fetchCommunicationSettings())
        .then((result) => {

        })
        .catch((error) => {

        });
    }
  }, [dispatch]);

  useEffect(() => {
    if (isUpdate && transportId) {
      dispatch(fetchTransportById(transportId));
    }
  }, [isUpdate, transportId]);


  useEffect(() => {

    if (isUpdate && transportData) {

      reset({
        vehicle_type: transportData.vehicle_type,
        capacity: transportData.capacity,
        volume: transportData.volume,
        comments: transportData.comments
      });

      setSelectedCoordinates({
        lng: transportData.longitude,
        lat: transportData.latitude,
      });

    }
  }, [isUpdate, transportData]);


  useEffect(() => {
    if (transportError) {
      toggleFormFields(false);
    }
  }, [transportError]);

  useEffect(() => {

    if (transportSuccess) {

      startTimer();
    }
    else {

    }
  }, [transportSuccess]);

  useEffect(() => {
    dispatch(resetAddTransportStatus());//clear status
  }, [location.pathname]);

  useEffect(() => {
    if (personeError) {
      setShowCommunicationSettings(true);
    }
  }, [personeError]);

  const toggleFormFields = (disabled) => {
    const submitButton = document.querySelector('button[type="submit"]');
    if (submitButton) {
      submitButton.disabled = disabled;
    }
  };

  const startTimer = () => {
    const timer = setTimeout(() => {
      toggleFormFields(false);
      dispatch(resetAddTransportStatus());//clear status
      if (!isUpdate) {
        //navigate('/');
      }
    }, 1000);

    return () => clearTimeout(timer);
  };

  const onSubmit = async (data) => {
    try {
      if (!selectedCoordinates) {
        setLocationError('Please select a location');
        toggleFormFields(false);
      } else {
        toggleFormFields(true);

        data.longitude = selectedCoordinates.lng;
        data.latitude = selectedCoordinates.lat;

        if (isUpdate) {
          //transportId
          await dispatch(updateTransport({ transportId: transportId, data: data }));//передаю обьект в котором вижу что все норм

        }
        else {
          await dispatch(addTransport(data));
        }
      }
    } catch (error) {

    } finally {

    }
  };

  //map
  const openMapModal = () => {
    setIsMapModalOpen(true);
  };

  const closeMapModal = () => {
    setIsMapModalOpen(false);
  };
  //end map

  const closeCommunicationSettings = () => {
    setShowCommunicationSettings(false);
  };

  return (

    <div className="container-fluid">

      {showCommunicationSettings && (
        <div className="overlay">
          <div className="inner-div">
            <CommunicationSettingsModal closeDialog={closeCommunicationSettings} />
          </div>
        </div>)}
      <div className={isUpdate ? "updateTransport" : "adf-c"}>
        <div className="card">
          <h4 className="card-header transportHeader"><svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" className="bi bi-bus-front-fill" viewBox="0 0 16 16">
            <path d="M16 7a1 1 0 0 1-1 1v3.5c0 .818-.393 1.544-1 2v2a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1-.5-.5V14H5v1.5a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1-.5-.5v-2a2.496 2.496 0 0 1-1-2V8a1 1 0 0 1-1-1V5a1 1 0 0 1 1-1V2.64C1 1.452 1.845.408 3.064.268A43.608 43.608 0 0 1 8 0c2.1 0 3.792.136 4.936.268C14.155.408 15 1.452 15 2.64V4a1 1 0 0 1 1 1v2ZM3.552 3.22A43.306 43.306 0 0 1 8 3c1.837 0 3.353.107 4.448.22a.5.5 0 0 0 .104-.994A44.304 44.304 0 0 0 8 2c-1.876 0-3.426.109-4.552.226a.5.5 0 1 0 .104.994ZM8 4c-1.876 0-3.426.109-4.552.226A.5.5 0 0 0 3 4.723v3.554a.5.5 0 0 0 .448.497C4.574 8.891 6.124 9 8 9c1.876 0 3.426-.109 4.552-.226A.5.5 0 0 0 13 8.277V4.723a.5.5 0 0 0-.448-.497A44.304 44.304 0 0 0 8 4Zm-3 7a1 1 0 1 0-2 0 1 1 0 0 0 2 0Zm8 0a1 1 0 1 0-2 0 1 1 0 0 0 2 0Zm-7 0a1 1 0 0 0 1 1h2a1 1 0 1 0 0-2H7a1 1 0 0 0-1 1Z" />
          </svg>&nbsp;{isUpdate ? 'Update Transport' : 'Add Transport'}</h4>
          <div className="card-body">
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="row rowcargo">
                {/*  vehicle_type */}
                <div className={`form-group w48perc ${errors.vehicle_type && 'has-error'}`}>
                  <label htmlFor="vehicle_type">Type of transport</label>
                  <select
                    id="vehicle_type"
                    name="vehicle_type"
                    {...register('vehicle_type')}
                    className="form-control"
                    required
                  >
                    <option value="">Select a type</option>
                    <optgroup label="Truck">
                      <option value="Dump Truck">Dump Truck</option>
                      <option value="Flatbed Truck">Flatbed Truck</option>
                      <option value="Tanker Truck">Tanker Truck</option>
                      <option value="Container Truck">Container Truck</option>
                      <option value="Truck and Trailer">Truck and Trailer</option>
                      <option value="Open Bed Truck">Open Bed Truck</option>
                      <option value="Refrigerated Truck">Refrigerated Truck</option>
                      <option value="Side-Loading Truck">Side-Loading Truck</option>
                      <option value="Lumber Carrier">Lumber Carrier</option>
                      <option value="Livestock Carrier">Livestock Carrier</option>
                      <option value="Horse Carrier">Horse Carrier</option>
                      <option value="Pickup Truck">Pickup Truck</option>
                      <option value="Tow Truck">Tow Truck</option>
                    </optgroup>
                    <optgroup label="Van (Van Body)">
                      <option value="Single Cab Van">Single Cab Van</option>
                      <option value="Double Cab Van">Double Cab Van</option>
                      <option value="Refrigerated Van (Refrigerator)">Refrigerated Van (Refrigerator)</option>
                      <option value="Van with Liftgate">Van with Liftgate</option>
                    </optgroup>
                    <optgroup label="Minivan">
                      <option value="Standard Minivan">Standard Minivan</option>
                      <option value="Long-Wheelbase Minivan">Long-Wheelbase Minivan</option>
                      <option value="Extended Minivan">Extended Minivan</option>
                      <option value="Minivan with High Roof">Minivan with High Roof</option>
                    </optgroup>
                    <optgroup label="Minibus">
                      <option value="Passenger Minibus (Public Buses)">Passenger Minibus (Public Buses)</option>
                      <option value="Cargo Minibus (Combination Buses)">Cargo Minibus (Combination Buses)</option>
                    </optgroup>
                  </select>
                  {errors.vehicle_type && (
                    <div className="text-danger">{errors.vehicle_type.message}</div>
                  )}
                </div>

                {/*  volume */}
                <div className={`form-group w48perc ${errors.volume && 'has-error'}`}>
                  <label htmlFor="volume">Volume (Cubic Meters)</label>
                  <input
                    type="number"
                    step="0.01"
                    id="volume"
                    name="volume"
                    {...register('volume')}
                    className="form-control"
                    required
                  />
                  {errors.volume && (
                    <div className="text-danger">{errors.volume.message}</div>
                  )}
                </div>
                
                {/*  Weight */}
                <div className={`form-group w48perc ${errors.weight && 'has-error'}`}>
                  <label htmlFor="capacity">Payload capacity (tonnes)</label>
                  <input
                    type="number"
                    step="0.01" // Устанавливаем шаг в 0.01 для десятичных значений
                    id="capacity"
                    name="capacity"
                    {...register('capacity')}
                    className="form-control"
                    required
                  />
                  {errors.capacity && (
                    <div className="text-danger">{errors.weight.capacity}</div>
                  )}
                </div>


              </div>
              {/* Location */}
              <div className={`form-group ${errors.current_location || locationError ? 'has-error' : ''}`}>
                <div>
                  <label htmlFor="current_location">Set location:</label>
                  <button className='btn btn-primary btnSetLocation transportBtnColor' type="button" onClick={openMapModal}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-geo-alt-fill" viewBox="0 0 16 16">
                      <path d="M8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10zm0-7a3 3 0 1 1 0-6 3 3 0 0 1 0 6z" />
                    </svg>
                  </button>
                </div>

                {isMapModalOpen ? (
                  <div className='locationPicker-container'><LocationPicker onLocationSelected={handleLocationSelected} closeMapModal={closeMapModal} coordinates={selectedCoordinates}/></div>
                ) : null}

                {/* Отобразите выбранные координаты, если они есть */}
                {selectedCoordinates && (
                  <div>
                    <div>Selected Coordinates:</div>
                    <div>Latitude: {selectedCoordinates.lat}</div>
                    <div>Longitude: {selectedCoordinates.lng}</div>
                  </div>
                )}

                {/* Ошибка, если не выбрано местоположение */}
                {errors.current_location || locationError ? (
                  <div className="text-danger">
                    {errors.current_location ? errors.current_location.message : locationError}
                  </div>
                ) : null}
              </div>
              {/* end location */}

              {/*  comments */}
              <div className={`form-group ${errors.additionalNotes && 'has-error'}`}>
                <label htmlFor="comments">Additional Notes</label>
                <textarea
                  type="text"
                  id="comments"
                  name="comments"
                  {...register('comments')}
                  className="form-control"
                  rows="3"
                  style={{ resize: "none" }}
                />
                {errors.comments && (
                  <div className="text-danger">{errors.comments.message}</div>
                )}
              </div>

              {transportError && (
                <div className="alert alert-danger">{transportError}</div>
              )}

              {isInProgress && (
                <div className="alert alert-success">{isUpdate ? 'Transport updating in process ...' : 'Transport adding in process ...'}</div>
              )}

              {transportSuccess && (
                <div className="alert alert-success">{isUpdate ? 'Transport updated successfully' : 'Transport added successfully'}</div>
              )}

              <div className="form-group btngroup">
                <button type="submit" className="btn btn-primary marginRight transportBtnColor">
                  {isUpdate ? 'Update Transport' : 'Add Transport'}
                </button>

                {isUpdate && (
                  <button type="button" className="btn btn-primary cancelBtn transportBtnColor"
                    onClick={handleCancelClick}>
                    Cancel
                  </button>
                )}
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

