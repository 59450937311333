import React from 'react';
import '../index.css';

export { Pagination };

const Pagination = ({ currentPage, totalPages, handleFirst, handlePrev, handleNext, handleLast }) => {
  return (
    <div className="pagination" >
      <button className='button-20' role="button" onClick={handleFirst} disabled={currentPage === 1}>First</button>
      <button className='button-20' role="button" onClick={handlePrev} disabled={currentPage === 1}>Previous</button>
      <button className='button-20' role="button" onClick={handleNext} disabled={currentPage === totalPages}>Next</button>
      <button className='button-20' role="button" onClick={handleLast} disabled={currentPage === totalPages}>Last</button>
    </div>
  );
};


