import { NavLink, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { authActions } from '_store';
import { Navbar, Nav, NavDropdown } from 'react-bootstrap';
import React, { useState, useEffect } from 'react';

export { MyNav };

function MyNav() {
    const authUser = useSelector((x) => x.auth.user);
    const dispatch = useDispatch();
    const logout = () => dispatch(authActions.logout());
    const location = useLocation();
    const [expanded, setExpanded] = useState(false);

    // Закрыть меню, если произошла навигация
    useEffect(() => {
        setExpanded(false);
    }, [location.pathname]);


    const toggleExpanded = () => {
        setExpanded(!expanded);
    };

    return (
        <Navbar bg="dark" variant="dark" 
            expand="md"
            expanded={expanded}
            onToggle={toggleExpanded}>
            <Navbar.Brand as={NavLink} to="/">
                <img src="/img/clover.png" alt="Home" className="img-icon" />
                &nbsp;DriveCargo<div className='logoPart'>I r e l a n d</div>
            </Navbar.Brand>
            <Navbar.Toggle onClick={() => setExpanded(!expanded)} aria-controls="navbarNav" />
            <Navbar.Collapse id="navbarNav" className={expanded ? 'show' : ''}>
                <Nav className="mr-auto">
                    <Nav.Link as={NavLink} to="/addcargo">
                        Add Cargo
                    </Nav.Link>
                    <Nav.Link as={NavLink} to="/addtransport">
                        Add Transport
                    </Nav.Link>
                    <Nav.Link as={NavLink} to="/searchtransport">
                        Search Transport
                    </Nav.Link>
                    <Nav.Link as={NavLink} to="/searchcargo">
                        Search Cargo
                    </Nav.Link>
                    <Nav.Link as={NavLink} to="/dashboard">
                        Dashboard
                    </Nav.Link>
                </Nav>
                <Nav>
                    <Nav.Link
                        onClick={() => {
                            logout();
                        }}
                        as={NavLink}
                        to="/login"
                    >
                        {authUser ? 'Logout' : 'Login'}
                    </Nav.Link>
                </Nav>
            </Navbar.Collapse>
        </Navbar>
    );
}
