import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { registerUser } from '_store/registration.slice';
import { resetRegistrationStatus } from '_store/registration.slice';
import '../index.css';

export function Registration() {
  const dispatch = useDispatch();
  const registrationError = useSelector((state) => state.registration.registrationError);
  const registrationSuccess = useSelector((state) => state.registration.registrationSuccess);
  const navigate = useNavigate();


  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .email('Invalid email address')
      .required('Email is required'),
    password: Yup.string()
      .matches(
        /^(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{6,}$/,
        'Password must contain at least one digit, one uppercase letter, and one of the following special characters: @$!%*?&'
      )
      .required('Password is required'),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref('password'), null], 'Passwords must match')
      .required('Confirming the password is required'),
  });
  

  const formOptions = { resolver: yupResolver(validationSchema) };
  const { register, handleSubmit, formState, reset } = useForm(formOptions);
  const { errors, isSubmitting } = formState;

  useEffect(() => {
    if (registrationSuccess) {
      startTimer();
    }
    else
    {
      toggleFormFields(false);
    }
  }, [registrationSuccess]);

  const toggleFormFields = (disabled) => {
    document.getElementById('email').disabled = disabled;
    document.getElementById('password').disabled = disabled;
    document.getElementById('confirmPassword').disabled = disabled;
    document.querySelector('button[type="submit"]').disabled = disabled;
  };
  
  const startTimer = () => {
    const timer = setTimeout(() => {
      dispatch(resetRegistrationStatus());//clear status
      navigate('/login');
    }, 3000);

    return () => clearTimeout(timer);
  };
  
  const onSubmit = async (data) => {
 
    try {
      toggleFormFields(true);
      await dispatch(registerUser(data));
    } catch (error) {
      toggleFormFields(false);
    } finally {
    }
  };

  return (
    <div className="col-md-6 offset-md-3 mscc">
      <div className="card bz">
        <h4 className="card-header">Registration</h4>
        <div className="card-body">
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className={`form-group ${errors.email && 'has-error'}`}>
              <label htmlFor="email">Email</label>
              <input
                type="email"
                id="email"
                name="email"
                {...register('email')}
                className="form-control"
                required
              />
              {errors.email && (
                <div className="text-danger">{errors.email.message}</div>
              )}
            </div>
            <div className={`form-group ${errors.password && 'has-error'}`}>
              <label htmlFor="password">Password</label>
              <input
                type="password"
                id="password"
                name="password"
                {...register('password')}
                className="form-control"
                required
              />
              {errors.password && (
                <div className="text-danger">{errors.password.message}</div>
              )}
            </div>
            <div
              className={`form-group ${
                errors.confirmPassword && 'has-error'
              }`}
            >
              <label htmlFor="confirmPassword">Confirm Password</label>
              <input
                type="password"
                id="confirmPassword"
                name="confirmPassword"
                {...register('confirmPassword')}
                className="form-control"
                required
              />
              {errors.confirmPassword && (
                <div className="text-danger">
                  {errors.confirmPassword.message}
                </div>
              )}
            </div>

            {registrationError && (
              <div className="alert alert-danger">{registrationError}</div>
            )}

            {registrationSuccess && (
              <div className="alert alert-success">Registration successful</div>
            )}

            <div className="form-group">
              <Link to="/login">Back to login</Link>
            </div>

            <div className="form-group">
              <button type="submit" className="btn btn-primary">
                Register
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
