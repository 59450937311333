//cargo.slice.js
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { history, fetchWrapper } from '_helpers';

const name = 'cargo';

const initialState = {
  cargo: [], 
  adding: false,
  cargoError: null,
  cargoSuccess: false,
  cargoData: null
};

export const addCargo = createAsyncThunk(
  `${name}/cargos`,
  async (userData) => {

    //console.log("createAsyncThunk before");
    const response = await fetchWrapper.post(`${process.env.REACT_APP_API_URL}/cargos/add`, userData);
    //console.log("createAsyncThunk after: ");

    //console.log(JSON.stringify(response));

    if (response.success === true) {
      return true;
    } else {
      const error = response.error || 'add cargo failed'; // Проверьте, есть ли сообщение об ошибке в ответе
      //console.log("createAsyncThunk error: "+error);
      throw new Error(error);
    }
  }
);

export const updateCargo = createAsyncThunk(
  `${name}/update`,
  async ({ cargoId, data }) => {

    const response = await fetchWrapper.put(`${process.env.REACT_APP_API_URL}/cargos/${cargoId}`, data);

    if (response.success === true) {
      return true;
    } else {
      const error = response.error || 'update cargo failed';
      throw new Error(error);
    }
  }
);

export const fetchCargoById = createAsyncThunk(
  `${name}/getById`,
  async (cargoId, { rejectWithValue }) => {
    try {

      const response = await fetchWrapper.get(`${process.env.REACT_APP_API_URL}/cargos/${cargoId}`);
      //console.log(JSON.stringify(response));

      if (response.success) {
        //console.log("data.success");
        return response.cargo;
      } else {
        //console.log("fetch cargo failed");
        return rejectWithValue(response.error || 'fetch cargo failed');
      }
    } catch (error) {
      //console.log("fetch cargo failed error");
      return rejectWithValue('fetch cargo failed');
    }
  }
);

// get cargos from server
export const fetchCargos = createAsyncThunk(
  `${name}/fetchCargos`,
  async ({ currentPage, itemsPerPage }) => {

    const url = `${process.env.REACT_APP_API_URL}/cargos/get-by-userid?page=${currentPage}&items=${itemsPerPage}`;
    const response = await fetchWrapper.get(url);
    //console.log(JSON.stringify(response));

    if (response.success === true) {
      return response.cargoInfoDto; 
    } else {
      const error = response.error || 'fetch cargos failed';
      throw new Error(error);
    }
  }
);

/*
const queryData = {
  loadingPlace: "",  // Место загрузки
  unloadingPlace: "",  // Место выгрузки
  weightFrom: 0,  // Вес от
  weightTo: 100000,  // Вес до
  volumeFrom: 0,  // Объем от
  volumeTo: 1000,  // Объем до
  priceFrom: 0,  // Цена от
  priceTo: 1000000,  // Цена до
  distanceFrom: 0,  // Расстояние от
  distanceTo: 10000,  // Расстояние до
  typeOfCargo: "",  // Тип груза
};
*/

export const searchCargos = createAsyncThunk(
  `${name}/searchCargos`,
  async ({ currentPage, itemsPerPage, queryData }) => {

    if (queryData.weightFrom === "") {
      queryData.weightFrom = 0;
    }
    if (queryData.weightTo === "") {
      queryData.weightTo = 100000;
    }
    if (queryData.volumeFrom === "") {
      queryData.volumeFrom = 0;
    }
    if (queryData.volumeTo === "") {
      queryData.volumeTo = 1000;
    }
    if (queryData.priceFrom === "") {
      queryData.priceFrom = 0;
    }
    if (queryData.priceTo === "") {
      queryData.priceTo = 1000000;
    }
    if (queryData.distanceFrom === "") {
      queryData.distanceFrom = 0;
    }
    if (queryData.distanceTo === "") {
      queryData.distanceTo = 10000;
    }

    //console.log("currentPage: "+currentPage);
    //console.log("itemsPerPage: "+itemsPerPage);
    //console.log(JSON.stringify(queryData));

    const url = `${process.env.REACT_APP_API_URL}/cargos/search?page=${currentPage}&items=${itemsPerPage}`;
    const response = await fetchWrapper.post(url,queryData);

    //console.log(JSON.stringify(response));

    if (response.success === true) {
      return response.cargoInfoDto; 
    } else {
      const error = response.error || 'fetch cargos failed';
      throw new Error(error);
    }
  }
);

//remove cargo
export const deleteCargo = createAsyncThunk(
  `${name}/deleteCargo`,
  async (cargoId) => {
    const response = await fetchWrapper.delete(`${process.env.REACT_APP_API_URL}/cargos/${cargoId}`);

    if (response.success === true) {
      return cargoId; // Возвращаем ID удаленного груза
    } else {
      const error = response.error || 'delete cargo failed';
      console.log("delete error: "+error);
      throw new Error(error);
    }
  }
);

const addCargoSlice = createSlice({
  name,
  initialState,
  reducers: {
    resetAddCargoStatus: (state) => {
      //console.log("resetAddCargoStatus");
      state.cargoSuccess = null;
      state.cargoError = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(addCargo.pending, (state) => {
        state.adding = true;
        state.cargoError = null;
        state.cargoSuccess = false;
      })
      .addCase(addCargo.fulfilled, (state, action) => {
        state.adding = false;
        state.cargoSuccess = true;
        //state.cargos.push(action.payload); // we not need to push as we get it from the server
      })
      .addCase(addCargo.rejected, (state, action) => {

        state.adding = false;
        state.cargoError = action.error.message;
        state.cargoSuccess = false;
      })
      .addCase(deleteCargo.pending, (state) => {
        //console.log("delete pending");
        state.deleting = true;
        state.cargoError = null;
        state.cargoSuccess = false;
      })
      .addCase(deleteCargo.fulfilled, (state, action) => {
        //console.log("delete fulfilled");
        state.deleting = false;
        state.cargoSuccess = true;
        state.cargo = state.cargo.filter(c => c.id !== action.payload);
      })
      .addCase(deleteCargo.rejected, (state, action) => {
        //console.log("delete rejected");
        state.deleting = false;
        state.cargoError = action.error.message;
        state.cargoSuccess = false;
      })
      .addCase(fetchCargos.fulfilled, (state, action) => {
        state.cargos = action.payload;
      })

      .addCase(fetchCargoById.fulfilled, (state, action) => {
        //console.log("fetchCargoById fulfilled");
        state.cargoData = action.payload; 
      })
      .addCase(searchCargos.fulfilled, (state, action) => {
        //console.log("fetchCargoById fulfilled");
        state.cargos = action.payload;
      })
      .addCase(fetchCargoById.rejected, (state, action) => {
        console.log("fetchCargoById rejected");
        state.cargoError = action.error.message;
        state.cargoData = null; 
      })
      .addCase(updateCargo.pending, (state) => {
        //state.updating = true;
        state.adding = true;
        state.updateError = null;
        state.updateCargoSuccess = false;
        state.cargoError = null;
        state.cargoSuccess = false;
      })
      .addCase(updateCargo.fulfilled, (state, action) => {
        //state.updating = false;
        state.adding = false;
        state.updateCargoSuccess = true;
        state.cargoSuccess = true;
      })
      .addCase(updateCargo.rejected, (state, action) => {
        //state.updating = false;
        state.adding = false;
        state.updateError = action.error.message;
        state.updateCargoSuccess = false;

        state.cargoError = action.error.message;
        state.tcargoSuccess = false;
      });
  },
});



export const { resetAddCargoStatus } = addCargoSlice.actions;
export const addCargoActions = addCargoSlice.actions;
export default addCargoSlice.reducer;

