//_components/LocationPicker.jsx
import React, { useState, useEffect } from 'react';

import mapboxgl from 'mapbox-gl';

const MAPBOX_API_KEY = 'pk.eyJ1IjoiZ3VhcmRpdCIsImEiOiJjbG5ycXUwaHMxYW1rMmlzNjl3enRieHFrIn0.K5vkrXP714qNyN1YTOegZQ';

export { LocationPicker };

const LocationPicker = ({ onLocationSelected, closeMapModal, coordinates }) => {
  const [map, setMap] = useState(null);
  const [selectedCoordinates, setSelectedCoordinates] = useState(null);

  // Определение границ Ирландии
  const irelandBounds = {
    north: 55.5,  // Максимальная широта
    south: 51.0,  // Минимальная широта
    west: -10.0, // Минимальная долгота
    east: -5.0   // Максимальная долгота
  };

  const expansionFactor = 0.5;  // 10% увеличение

  const expandedIrelandBounds = {
    north: irelandBounds.north * (1 + expansionFactor),
    south: irelandBounds.south * (1 - expansionFactor),
    west: irelandBounds.west * (1 - expansionFactor),
    east: irelandBounds.east * (1 + expansionFactor)
  };

  useEffect(() => {
    mapboxgl.accessToken = MAPBOX_API_KEY;
    const newMap = new mapboxgl.Map({
      container: 'map-container',
      style: 'mapbox://styles/mapbox/streets-v11',
      center: [-7.5, 53.25],
      zoom: 6,

      minZoom: 6,  // Минимальный уровень зума
    });

    let marker = null;

    newMap.on('load', () => {
      setMap(newMap);

      if (coordinates) {
        // Создаем новую метку если уже была выбрана
        marker = new mapboxgl.Marker()
          .setLngLat(coordinates)
          .addTo(newMap);
        // Обновляем состояние метки
        //setMarker(newMarker);
      }

      // Создаем обработчик для клика на карту после загрузки
      newMap.on('click', (e) => {
        const coordinates = e.lngLat;
        setSelectedCoordinates(coordinates);

        if (marker) {
          marker.remove();
        }

        // Создаем новую метку
        marker = new mapboxgl.Marker()
          .setLngLat(coordinates)
          .addTo(newMap);
      });

      newMap.on('dblclick', (e) => {
        // Получите текущий уровень масштабирования карты
        const currentZoom = newMap.getZoom();

        // Увеличьте масштаб карты
        newMap.setZoom(currentZoom + 1);

        // Если нужно уменьшить масштаб, вы можете использовать:
        // newMap.setZoom(currentZoom - 1);
      });

      /*
          newMap.on('move', () => {
            const bounds = newMap.getBounds();
          //console.log("move");
            // Проверка, выходят ли текущие границы карты за пределы Ирландии
            if (
              bounds.getNorth() > expandedIrelandBounds.north ||
              bounds.getSouth() < expandedIrelandBounds.south ||
              bounds.getWest() < expandedIrelandBounds.west ||
              bounds.getEast() > expandedIrelandBounds.east
            ) {
              // Ограничение координат на карте
              newMap.fitBounds([irelandBounds.west, irelandBounds.south, irelandBounds.east, irelandBounds.north]);
      
              //newMap.setCenter([-7.5, 53.25]);
            }
          });*/
    });



    //was
    /*
    newMap.on('load', () => {
      setMap(newMap);
    });
 

    newMap.on('click', (e) => {
      const coordinates = e.lngLat;
      setSelectedCoordinates(coordinates);

      // Создаем новую метку
      const newMarker = new mapboxgl.Marker()
        .setLngLat(coordinates)
        .addTo(map);

      // Обновляем состояние метки
      //setMarker(newMarker);

    });*/


    // Очистка ресурсов карты при размонтировании компонента
    return () => {
      if (map) {
        map.remove();
      }
    };
  }, []);

  const handleAcceptLocation = () => {
    if (selectedCoordinates) {
      onLocationSelected(selectedCoordinates);
      setSelectedCoordinates(null);
      if (closeMapModal) {
        closeMapModal(); // Закрываем модальное окно, если функция существует
      }
    }
  };

  const handleClose = () => {
    closeMapModal();
  };

  return (
    <div id="location-picker">
      <div id="map-container" style={{ height: '70vh', width: '80vw' }}></div>
      <div id="map-f">
        <div className='selectedCoordinates'>
          Selected Coordinates:<br />
          Latitude: {selectedCoordinates ? selectedCoordinates.lat : ''}<br />
          Longitude: {selectedCoordinates ? selectedCoordinates.lng : ''}
        </div>
        <div className='btnAcceptLocation'>
          <button className='btn btn-primary bal' type="button" onClick={handleAcceptLocation}>Accept Location</button>
          <button className='btn btn-primary' type="button" onClick={handleClose}>Close</button>
        </div>
      </div>
    </div>
  );
};
