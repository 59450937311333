//_store/index.js

import { configureStore } from '@reduxjs/toolkit';

import { authReducer } from './auth.slice';
import { usersReducer } from './users.slice';
import registrationReducer from './registration.slice';
import passwordRecoveryReducer from './passwordRecovery.slice'; 
import cargoReducer from './cargo.slice';
import transportReducer from './transport.slice';
import personeReducer from './persone.slice';

export * from './auth.slice';
export * from './users.slice';
export * from './registration.slice';
export * from './passwordRecovery.slice'; 
export * from  './cargo.slice';
export * from  './transport.slice';
export * from  './persone.slice';

export const store = configureStore({
  reducer: {
    auth: authReducer,
    users: usersReducer,
    registration: registrationReducer,
    passwordRecovery: passwordRecoveryReducer, 
    cargo: cargoReducer,
    transport: transportReducer,
    persone: personeReducer
  }
});
