//registration.slice.js
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { history, fetchWrapper } from '_helpers';

const name = 'registration';

const initialState = {
  registering: false,
  registrationError: null,
  registrationSuccess: false,
};

export const registerUser = createAsyncThunk(
  `${name}/register`,
  async (userData) => {
    const response = await fetchWrapper.post(`${process.env.REACT_APP_API_URL}/auth/register`, userData);

    //const data = await response.json();

    if (response.success === true) {
      return true;
    } else {
      const error = response.error || 'Registration failed'; // Проверьте, есть ли сообщение об ошибке в ответе
      throw new Error(error);
    }
  }
);

const registrationSlice = createSlice({
  name,
  initialState,
  reducers: {
      resetRegistrationStatus: (state) => {
        state.registrationSuccess = null;
        state.registrationError = null;
      }
  },
  extraReducers: (builder) => {
    builder
      .addCase(registerUser.pending, (state) => {
        state.registering = true;
        state.registrationError = null;
        state.registrationSuccess = false;
      })
      .addCase(registerUser.fulfilled, (state) => {
        state.registering = false;
        state.registrationSuccess = true;
        // После успешной регистрации перенаправляем пользователя на страницу входа
        //history.navigate('/login');
      })
      .addCase(registerUser.rejected, (state, action) => {
        state.registering = false;
        state.registrationError = action.error.message;
        state.registrationSuccess = false;
      });
  },
});

export const { resetRegistrationStatus } = registrationSlice.actions;
export const registrationActions = registrationSlice.actions;
export default registrationSlice.reducer;
