import React, { useState, useEffect } from 'react';
import { updateCommunicationSettings, fetchCommunicationSettings,resetAddPersoneStatus } from '_store/persone.slice';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { yupResolver } from '@hookform/resolvers/yup'; // Импортируйте yupResolver
import * as yup from 'yup';

export { CommunicationSettingsModal };

const phoneRegExp = /^[+0-9]+$/;

const validationSchema = yup.object().shape({
    name: yup.string().max(100).required('Name is required'),
    cell1: yup.string().max(20).min(6).required().matches(phoneRegExp, 'Invalid phone number'),
    cell2: yup
        .string()
        .max(20, 'Cell2 should not exceed 20 characters')
        .matches(phoneRegExp, 'Invalid phone number')
        .transform((value, originalValue) => {
            return originalValue === '' ? null : value;
        }).nullable(),
    whatsapp: yup
    .string()
    .max(20, 'whatsapp should not exceed 20 characters')
    .matches(phoneRegExp, 'Invalid phone number')
    .transform((value, originalValue) => {
        return originalValue === '' ? null : value;
    }).nullable(),
    skype: yup.string().max(20),
    viber:yup
    .string()
    .max(20, 'viber should not exceed 20 characters')
    .matches(phoneRegExp, 'Invalid phone number')
    .transform((value, originalValue) => {
        return originalValue === '' ? null : value;
    }).nullable(),
    telegram: yup.string().max(20),
});

const CommunicationSettingsModal = ({closeDialog}) => {
    const dispatch = useDispatch();
  const { reset, handleSubmit, register, formState: { errors } } = useForm({
    resolver: yupResolver(validationSchema),
  });
    const [showForm, setShowForm] = useState(false);
    const updatePersoneSuccess = useSelector((state) => state.persone.updatePersoneSuccess);
    //const personeError = useSelector((state) => state.persone.personeError);
    const personeData = useSelector((state) => state.persone.personeData);

      useEffect(() => {
        if(updatePersoneSuccess){
            setShowForm(false);
            closeDialog();
        }
      }, [updatePersoneSuccess]);

      useEffect(() => {
        if(!personeData){
           console.log("show csm dialog");
           setShowForm(true);
        }
      }, [personeData]);

    const onSubmit = async (data) => {
        try {
            await dispatch(updateCommunicationSettings({ data }));
        } catch (error) {
        } finally {
        }
    };

    return showForm && (

        <div className='csmC'>
            <div className='alert alert-warning' role="alert">First set up сommunication settings please.</div>
            <p><strong>Communication Settings:</strong></p> 

                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="row">
                        <div className="col-md-5">
                            <div className="form-group">
                                <div className="communication-item">Contact persone name *:</div>
                                <input
                                    type="text"
                                    name="name"
                                    {...register('name')}
                                    className={`form-control ${errors.name ? 'is-invalid' : ''}`}
                                />
                                {errors.name && (
                                    <div className="invalid-feedback">Name required and should not exceed 50 characters</div>
                                )}
                            </div>
                        </div>
                        <div className="col-md-5">
                            <div className="form-group">
                                <div className="communication-item">Cell *:</div>
                                <input
                                    type="text"
                                    name="cell1"
                                    {...register('cell1')}
                                    className={`form-control ${errors.cell1 ? 'is-invalid' : ''}`}
                                />
                                {errors.cell1 && (
                                    <div className="invalid-feedback">Cell required and should not exceed 20 characters</div>
                                )}
                            </div>
                        </div>
                        <div className="col-md-5">
                            <div className="form-group">
                                <div className="communication-item">Cell 2:</div>
                                <input
                                    type="text"
                                    name="cell2"
                                    {...register('cell2')}
                                    className={`form-control ${errors.cell2 ? 'is-invalid' : ''}`}
                                />
                                {errors.cell2 && (
                                    <div className="invalid-feedback">Cell2 should not exceed 20 characters</div>
                                )}
                            </div>
                        </div>
                        <div className="col-md-5">
                            <div className="form-group">
                                <div className="communication-item">WhatsApp:</div>
                                <input
                                    type="text"
                                    name="whatsapp"
                                    {...register('whatsapp')}
                                    className={`form-control ${errors.whatsapp ? 'is-invalid' : ''}`}
                                />
                                {errors.whatsapp && (
                                    <div className="invalid-feedback">WhatsApp should not exceed 20 characters</div>
                                )}
                            </div>
                        </div>
                        <div className="col-md-5">
                            <div className="form-group">
                                <div className="communication-item">Skype:</div>
                                <input
                                    type="text"
                                    name="skype"
                                    {...register('skype')}
                                    className={`form-control ${errors.skype ? 'is-invalid' : ''}`}
                                />
                                {errors.skype && (
                                    <div className="invalid-feedback">Skype should not exceed 20 characters</div>
                                )}
                            </div>
                        </div>
                        <div className="col-md-5">
                            <div className="form-group">
                                <div className="communication-item">Viber:</div>
                                <input
                                    type="text"
                                    name="viber"
                                    {...register('viber')}
                                    className={`form-control ${errors.viber ? 'is-invalid' : ''}`}
                                />
                                {errors.viber && (
                                    <div className="invalid-feedback">Viber should not exceed 20 characters</div>
                                )}
                            </div>
                        </div>
                        <div className="col-md-5">
                            <div className="form-group">
                                <div className="communication-item">Telegram:</div>
                                <input
                                    type="text"
                                    name="telegram"
                                    {...register('telegram')}
                                    className={`form-control ${errors.telegram ? 'is-invalid' : ''}`}
                                />
                                {errors.telegram && (
                                    <div className="invalid-feedback">Telegram should not exceed 20 characters</div>
                                )}
                            </div>
                        </div>
                    </div>

                    <div className="form-group">
                        <button type="submit" className="btn btn-secondary">
                            Update
                        </button>&nbsp;
                    </div>
                </form>
        </div>
    );
};
