//Home.jsx
import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import '../index.css';

export { Home };

function Home() {
  const dispatch = useDispatch();

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-md-4">
          <div className="card infoBlock">
           
            <img src="img/car1.png" alt="cargo" className="imgHome1" />
            <ul>
              <li>The DriveCargo project is an information service that accommodates various participants in the logistics market, such as dispatch agencies, independent dispatchers, drivers, transportation companies, as well as businesses and individuals in need of cargo transportation.</li>
              <li>Dispatchers can earn up to 25% of the cargo's value by using the DriveCargo service. They can oversee the transportation process via phone or messaging, allowing drivers to focus on their work without distractions.</li>
              <li>Cargo owners can easily find suitable carriers on the CargoDrive portal.</li>
              <li>Additionally, drivers can independently search for cargo.</li>
              <li>One of the unique features of our service is its simplicity. You only need to provide approximate location information, enhancing security. You need to specify your location only on a broad scale, represented as coordinates on the map. For cargo, you need to specify the loading and unloading points. Our algorithm will easily find the cargo that suits your requirements, based on price and distance.</li>
              <li>Join us and conduct your business with DriveCargo. Transport cargo with DriveCargo service.</li>
            </ul>
          </div>
        </div>
        <div className="col-md-4">
          <div className="card infoBlock">
            <img src="img/dc1.png" alt="cargo" className="imgHome1" />
            <ul>
              <li>Are you moving? Do you need to transport cargo?</li>
              <li>If you're looking for a way to move or transport cargo, DriveCargo is here to help.</li>
              <li>We provide a platform for individuals and businesses to find transportation solutions that suit their needs.</li>
              <li>Whether you're a cargo owner or someone in need of transportation services, DriveCargo can connect you with the right service providers.</li>
              <li>Get started with DriveCargo today and simplify your transportation needs.</li>
            </ul>
            <img src="img/car12.png" alt="cargo" className="imgHome1" />
          </div>
        </div>
        <div className="col-md-4">
          <div className="card infoBlock">
            <img src="img/dc3.png" alt="cargo" className="imgHome1" />
            <ul>
              <li>Do you own commercial transport? Are you looking for new clients?</li>
              <li>If you own commercial transport or are seeking new clients for your transportation services, DriveCargo can connect you with the right people.</li>
              <li>We provide a platform where transport service providers and potential clients can easily find each other.</li>
              <li>Whether you're a transportation company or an individual looking for transportation services, DriveCargo is here to simplify the process and help you expand your business.</li>
              <li>Get started with DriveCargo today and reach new clients effortlessly.</li>
            </ul>
            <img src="img/car2.png" alt="cargo" className="imgHome1" />
          </div>
        </div>
      </div>
    </div>
  );
}
