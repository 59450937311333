//persone.slice.js
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { fetchWrapper } from '_helpers';

const name = 'persone';

const initialState = {
  persone: [],
  adding: false,
  personeError: null,
  personeSuccess: false,
  personeData: null
};

export const updateCommunicationSettings = createAsyncThunk(
  `${name}/updateCommunicationSettings`,
  async ({ data }) => {

    const response = await fetchWrapper.put(`${process.env.REACT_APP_API_URL}/persones`, data);

    //console.log(JSON.stringify(response));

    if (response.success === true) {
      return true;
    } else {
      const error = response.error || 'update CommunicationSettings failed';
      throw new Error(error);
    }
  }
);

// get persones from server
export const fetchCommunicationSettings = createAsyncThunk(
  `${name}/fetchCommunicationSettings`,
  async () => {

    const url = `${process.env.REACT_APP_API_URL}/persones/get-by-userid`;
    const response = await fetchWrapper.get(url);

    //console.log(JSON.stringify(response));

    if (response.success === true) {
      return response.сommunicationSettingsData;  
    } else {
      const error = response.error || 'fetch CommunicationSettings failed';
      throw new Error(error);
    }
  }
);

export const fetchContactInfo = createAsyncThunk(
  `${name}/fetchContactInfo`,
  async ({entityType,selectedId}) => {

    const url = `${process.env.REACT_APP_API_URL}/persones/get-contact-info?entityType=${entityType}&id=${selectedId}`;
    const response = await fetchWrapper.get(url);

    //console.log(JSON.stringify(response));

    if (response.success === true) {
      return response.сommunicationSettingsData;  
    } else {
      const error = response.error || 'fetch CommunicationSettings failed';
      throw new Error(error);
    }
  }
);

const addPersoneSlice = createSlice({
  name,
  initialState,
  reducers: {
    resetAddPersoneStatus: (state) => {
      state.personeSuccess = null;
      state.personeError = null;
    },
    resetUpdatePersoneStatus: (state) => {
      state.updatePersoneSuccess = null;
      state.updateError = null;
    }
  },
  extraReducers: (builder) => {
    builder
    .addCase(fetchContactInfo.fulfilled, (state, action) => {
      //console.log("fetchContactInfo fulfilled");
      state.personeData = action.payload; 
    })
    .addCase(fetchContactInfo.rejected, (state, action) => {
      //console.log("fetchContactInfo rejected");
      state.personeError = action.error.message;
      state.personeData = null; 
    })
      .addCase(fetchCommunicationSettings.fulfilled, (state, action) => {
        //console.log("fetchCommunicationSettings fulfilled");
        state.personeData = action.payload; 
        state.personeSuccess = true; 
      })
      .addCase(fetchCommunicationSettings.rejected, (state, action) => {
        //console.log("fetchCommunicationSettings rejected");
        state.personeError = action.error.message;
        state.personeData = null;
        state.personeSuccess = false; 
      })
      .addCase(updateCommunicationSettings.pending, (state) => {
        state.updating = true;
        state.updateError = null;
        state.updatePersoneSuccess = false;
        state.personeError = null;
        state.personeSuccess = false;
      })
      .addCase(updateCommunicationSettings.fulfilled, (state, action) => {
        state.updating = false;
        state.updatePersoneSuccess = true;
        state.personeSuccess = true;
      })
      .addCase(updateCommunicationSettings.rejected, (state, action) => {
        state.updating = false;
        state.updateError = action.error.message;
        state.updatePersoneSuccess = false;

        state.personeError = action.error.message;
        state.personeSuccess = false;
      });
  },
});


export const { resetAddPersoneStatus } = addPersoneSlice.actions;
export const addPersoneActions = addPersoneSlice.actions;
export default addPersoneSlice.reducer;
