//transport.slice.js
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { fetchWrapper } from '_helpers';

const name = 'transport';

const initialState = {
  transport: [],
  adding: false,
  transportError: null,
  transportSuccess: false,
  transportData: null
};

export const addTransport = createAsyncThunk(
  `${name}/add`,
  async (userData) => {
    const response = await fetchWrapper.post(`${process.env.REACT_APP_API_URL}/transports/add`, userData);

    //console.log(JSON.stringify(response));
    //const data = await response.json();

    if (response.success === true) {
      return true;
    } else {
      const error = response.error || 'add transport failed';
      throw new Error(error);
    }
  }
);

export const updateTransport = createAsyncThunk(
  `${name}/update`,
  async ({ transportId, data }) => {

    const response = await fetchWrapper.put(`${process.env.REACT_APP_API_URL}/transports/${transportId}`, data);

    if (response.success === true) {
      return true;
    } else {
      const error = response.error || 'update transport failed';
      throw new Error(error);
    }
  }
);

export const fetchTransportById = createAsyncThunk(
  `${name}/getById`,
  async (transportId, { rejectWithValue }) => {
    try {

      const response = await fetchWrapper.get(`${process.env.REACT_APP_API_URL}/transports/${transportId}`);

      if (response.success) {

        return response.transport;
      } else {

        return rejectWithValue(response.error || 'fetch transport failed');
      }
    } catch (error) {

      return rejectWithValue('fetch transport failed');
    }
  }
);

// get transports from server
export const fetchTransports = createAsyncThunk(
  `${name}/fetchTransports`,
  async ({ currentPage, itemsPerPage }) => {

    const url = `${process.env.REACT_APP_API_URL}/transports/get-by-userid?page=${currentPage}&items=${itemsPerPage}`;
    const response = await fetchWrapper.get(url);

    //console.log(JSON.stringify(response));

    if (response.success === true) {
      return response.transportInfoDto;  
    } else {
      const error = response.error || 'fetch transports failed';
      throw new Error(error);
    }
  }
);

/*
const queryData = {
  vehicleType: "",   
  currentLocation: "",   
  volumeFrom: 0,  
  volumeTo: 1000,   
  capacityFrom: 0,  
  capacityTo: 100000
};
*/

export const searchTransports = createAsyncThunk(
  `${name}/searchTransports`,
  async ({ currentPage, itemsPerPage, queryData }) => {

    if (queryData.volumeFrom === "") {
      queryData.volumeFrom = 0;
    }
    if (queryData.volumeTo === "") {
      queryData.volumeTo = 100000;
    }
    if (queryData.capacityFrom === "") {
      queryData.capacityFrom = 0;
    }
    if (queryData.capacityTo === "") {
      queryData.capacityTo = 1000;
    }
 
    const url = `${process.env.REACT_APP_API_URL}/transports/search?page=${currentPage}&items=${itemsPerPage}`;
    const response = await fetchWrapper.post(url,queryData);

    //console.log(JSON.stringify(response));

    if (response.success === true) {
      return response.transportInfoDto; 
    } else {
      const error = response.error || 'fetch transports failed';
      throw new Error(error);
    }
  }
);

//remove Transport 
export const deleteTransport = createAsyncThunk(
  `${name}/deleteTransport`,
  async (transportId) => {
    const response = await fetchWrapper.delete(`${process.env.REACT_APP_API_URL}/transports/${transportId}`);

    if (response.success === true) {
      return transportId;
    } else {
      const error = response.error || 'delete transpor failed';
      throw new Error(error);
    }
  }
);

const addTransportSlice = createSlice({
  name,
  initialState,
  reducers: {
    resetAddTransportStatus: (state) => {
      state.transportSuccess = null;
      state.transportError = null;
    },
    resetUpdateTransportStatus: (state) => {
      state.updateTransportSuccess = null;
      state.updateError = null;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(addTransport.pending, (state) => {
        state.adding = true;
        state.transportError = null;
        state.transportSuccess = false;
      })
      .addCase(addTransport.fulfilled, (state, action) => {
        state.adding = false;
        state.transportSuccess = true;
        //state.transports.push(action.payload);// we not need to push as we get it from server
      })
      .addCase(addTransport.rejected, (state, action) => {
        state.adding = false;
        state.transportError = action.error.message;
        state.transportSuccess = false;
      })
      .addCase(deleteTransport.pending, (state) => {
        state.deleting = true;
        state.transportError = null;
        state.transportSuccess = false;
      })
      .addCase(deleteTransport.fulfilled, (state, action) => {
        state.deleting = false;
        state.transportSuccess = true;
        state.transport = state.transport.filter(c => c.id !== action.payload);
      })
      .addCase(deleteTransport.rejected, (state, action) => {
        state.deleting = false;
        state.transportError = action.error.message;
        state.transportSuccess = false;
      })
      .addCase(fetchTransports.fulfilled, (state, action) => {
       
        state.transports = action.payload; //init data from server 
      })
      .addCase(fetchTransportById.fulfilled, (state, action) => {
        //console.log("fetchTransportById fulfilled");
        state.transportData = action.payload; 
      })
      .addCase(searchTransports.fulfilled, (state, action) => {
        //console.log("fetchTransportById fulfilled");
        state.transports = action.payload;
      })
      .addCase(fetchTransportById.rejected, (state, action) => {

        state.transportError = action.error.message;
        state.transportData = null; 
      })
      .addCase(updateTransport.pending, (state) => {
        //state.updating = true;
        state.adding = true;
        state.updateError = null;
        state.updateTransportSuccess = false;
        state.transportError = null;
        state.transportSuccess = false;
      })
      .addCase(updateTransport.fulfilled, (state, action) => {
        //state.updating = false;
        state.adding = false;
        state.updateTransportSuccess = true;
        state.transportSuccess = true;
      })
      .addCase(updateTransport.rejected, (state, action) => {
        //state.updating = false;
        state.adding = false;
        state.updateError = action.error.message;
        state.updateTransportSuccess = false;

        state.transportError = action.error.message;
        state.transportSuccess = false;
      });
  },
});


export const { resetAddTransportStatus } = addTransportSlice.actions;
export const addTransportActions = addTransportSlice.actions;
export default addTransportSlice.reducer;
