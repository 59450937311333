// passwordRecovery.slice.js
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { fetchWrapper } from '_helpers';

const name = 'passwordRecovery';

const initialState = {
  recovering: false,
  recoveryError: null,
  recoverySuccess: false,
};

export const recoverPassword = createAsyncThunk(
  `${name}/recover`,
  async (userData) => {
    
    //alert(JSON.stringify(userData));
    const response = await fetchWrapper.post(`${process.env.REACT_APP_API_URL}/auth/recover`, userData );

    if (response.success === true) {
      return true;
    } else {
      const error = response.error || 'Password recovery failed';
      throw new Error(error);
    }
  }
);

const passwordRecoverySlice = createSlice({
  name,
  initialState,
  reducers: {
    resetRecoveryStatus: (state) => {
      state.recoverySuccess = false;
      state.recoveryError = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(recoverPassword.pending, (state) => {
        state.recovering = true;
        state.recoveryError = null;
        state.recoverySuccess = false;
      })
      .addCase(recoverPassword.fulfilled, (state) => {
        state.recovering = false;
        state.recoverySuccess = true;
      })
      .addCase(recoverPassword.rejected, (state, action) => {
        state.recovering = false;
        state.recoveryError = action.error.message;
        state.recoverySuccess = false;
      });
  },
});

export const { resetRecoveryStatus } = passwordRecoverySlice.actions;
export const passwordRecoveryActions = passwordRecoverySlice.actions;
export default passwordRecoverySlice.reducer;
