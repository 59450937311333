//src/searchcargo/SearchCargo.jsx

import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import {ContactInfo} from '_components';
import { authActions, userActions } from '_store';
import { searchCargos } from '_store/cargo.slice';
import { resetAddCargoStatus } from '_store/cargo.slice';
import { Pagination } from '_components';

import '../index.css';

export function SearchCargo() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchData, setSearchData] = useState({});
  const [showContactInfo, setShowContactInfo] = useState(false);
  const [selectedId, setSelectedId] = useState(0);
  //cargo
  const { user: authUser } = useSelector(x => x.auth);
  const logout = () => dispatch(authActions.logout());
  const { cargos } = useSelector((x) => x.cargo);
  const [selectedCargoRow, setCargoSelectedRow] = useState(null);
  const cargoSuccess = useSelector((state) => state.cargo.cargoSuccess);
  //end cargo

  // validation

  const validationSchema = Yup.object().shape({
    typeOfCargo: Yup.string().max(50, 'The "Type of Cargo" field should not exceed 50 characters'),
    loadingPlace: Yup.string().max(50, 'The "Loading Place" field should not exceed 50 characters'),
    unloadingPlace: Yup.string().max(50, 'The "Unloading Place" field should not exceed 50 characters'),
  });

  const formOptions = { resolver: yupResolver(validationSchema) };
  const { register, handleSubmit, formState, reset } = useForm(formOptions);
  const { errors, isSubmitting } = formState;

  //pagination cargo
  const [cargosLength, setCargosLength] = useState(0);
  const [cargostotalPages, setCargosTotalPages] = useState(0);
  const [cargosendIndex, setCargosEndIndex] = useState(0);
  const [cargoscurrentPage, setCargosCurrentPage] = useState(1);
  const cargositemsPerPage = 10; // Set the number of items per page here

  const handleFirst = () => {
    setCargosCurrentPage(1);
    dispatch(searchCargos({ currentPage: 1, itemsPerPage: cargositemsPerPage, queryData: searchData }));
  };

  const handlePrev = () => {
    setCargosCurrentPage(cargoscurrentPage - 1);
    dispatch(searchCargos({ currentPage: cargoscurrentPage - 1, itemsPerPage: cargositemsPerPage, queryData: searchData }));
  };

  const handleNext = () => {
    setCargosCurrentPage(cargoscurrentPage + 1);
    dispatch(searchCargos({ currentPage: cargoscurrentPage + 1, itemsPerPage: cargositemsPerPage, queryData: searchData }));
  };

  const handleLast = () => {
    if (cargostotalPages) {
      setCargosCurrentPage(cargostotalPages);
      dispatch(searchCargos({ currentPage: cargostotalPages, itemsPerPage: cargositemsPerPage, queryData: searchData }));
    }
  };

  useEffect(() => {
    if (cargos) {

      //console.log(JSON.stringify(cargos));

      const newCargosLength = cargos.total;
      const newTotalPages = Math.ceil(newCargosLength / cargositemsPerPage);
      const newstartIndex = (cargoscurrentPage - 1) * cargositemsPerPage;
      const newEndIndex = Math.min(newstartIndex + cargositemsPerPage, newCargosLength);
      //const visibleCargos = cargos.slice(startIndex, endIndex);

      setCargosLength(newCargosLength);
      setCargosTotalPages(newTotalPages);
      setCargosEndIndex(newEndIndex);
    }
  }, [cargos]);
  //end pagination cargo

  //cargo list 
  const handleCargoRowClick = (id) => {
    setCargoSelectedRow(id);
  };

  useEffect(() => {

    const emptyQueryData = {
      loadingPlace: "",
      unloadingPlace: "",
      weightFrom: 0,
      weightTo: 0,
      volumeFrom: 0,
      volumeTo: 0,
      priceFrom: 0,
      priceTo: 0,
      distanceFrom: 0,
      distanceTo: 0,
      typeOfCargo: "",
    };

    dispatch(searchCargos({ currentPage: cargoscurrentPage, itemsPerPage: cargositemsPerPage, queryData: emptyQueryData }));
    setSearchData(emptyQueryData);

  }, [dispatch]);


  const closeContactInfo = () => {
    setShowContactInfo(false);
  };

  const handleShowContactInfo = (id) => {
    setSelectedId(id);
    setShowContactInfo(true);
  };

  //end cargo list

  //common function
  function formatDate(dateString) {
    const date = new Date(dateString);
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear().toString().slice(2);
    const hours = date.getHours().toString().padStart(2, '0');
    const minutes = date.getMinutes().toString().padStart(2, '0');
    return `${day}.${month}.${year} - ${hours}:${minutes}`;
  }
  //end common function

  //Submit 
  const onSubmit = async (data) => {

    try {
      setCargosCurrentPage(1);
      await dispatch(searchCargos({ currentPage: 1, itemsPerPage: cargositemsPerPage, queryData: data }));
      setSearchData(data);
    } catch (error) {
    } finally {
    }
  };
  //end submit 

  return (
    <div className="container-fluid">

{showContactInfo && (
        <div className="overlay">
          <div className="inner-div">
          <ContactInfo closeContactInfo={closeContactInfo} entityType={"cargo"} selectedId={selectedId}  />
          </div>
        </div>)}

    <div className="adf-c">
      <div className="card search">
        <h4 className="card-header cargoColor"><svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" className="bi bi-box-seam isonHeader" viewBox="0 0 16 16">
          <path d="M8.186 1.113a.5.5 0 0 0-.372 0L1.846 3.5l2.404.961L10.404 2l-2.218-.887zm3.564 1.426L5.596 5 8 5.961 14.154 3.5l-2.404-.961zm3.25 1.7-6.5 2.6v7.922l6.5-2.6V4.24zM7.5 14.762V6.838L1 4.239v7.923l6.5 2.6zM7.443.184a1.5 1.5 0 0 1 1.114 0l7.129 2.852A.5.5 0 0 1 16 3.5v8.662a1 1 0 0 1-.629.928l-7.185 2.874a.5.5 0 0 1-.372 0L.63 13.09a1 1 0 0 1-.63-.928V3.5a.5.5 0 0 1 .314-.464L7.443.184z" />
        </svg>&nbsp;Search Cargo</h4>
        <div className="card-body">
          <form onSubmit={handleSubmit(onSubmit)}>


            <div className="row">
              <div className="col-md-6">
                <div className="form-group">
                  <label htmlFor="loadingPlace">Loading Place</label>
                  <input
                    type="text"
                    id="loadingPlace"
                    name="loadingPlace"
                    {...register('loadingPlace')}
                    className={`form-control ${errors.loadingPlace ? 'is-invalid' : ''}`}
                  />
                  {errors.loadingPlace && (
                    <div className="invalid-feedback">
                      Loading Place should not exceed 50 characters
                    </div>
                  )}
                </div>
              </div>

              <div className="col-md-6">
                <div className="form-group">
                  <label htmlFor="unloadingPlace">Unloading Place</label>
                  <input
                    type="text"
                    id="unloadingPlace"
                    name="unloadingPlace"
                    {...register('unloadingPlace')}
                    className={`form-control ${errors.unloadingPlace ? 'is-invalid' : ''}`}
                  />
                  {errors.unloadingPlace && (
                    <div className="invalid-feedback">
                      Unloading Place should not exceed 50 characters
                    </div>
                  )}
                </div>
              </div>


              <div className="col-md-6">
                <div className="form-group">
                  <label htmlFor="weight">Weight</label>
                  <div className="input-group">
                    <input
                      type="number"
                      id="weightFrom"
                      name="weightFrom"
                      {...register('weightFrom')}
                      className={`form-control ${errors.weightFrom ? 'is-invalid' : ''}`}
                      placeholder="From"
                      step="0.01"
                      max={100000}
                    />
                    <input
                      type="number"
                      id="weightTo"
                      name="weightTo"
                      {...register('weightTo')}
                      className={`form-control ${errors.weightTo ? 'is-invalid' : ''}`}
                      placeholder="To"
                      step="0.01"
                      max={100000}
                    />
                  </div>
                  {errors.weightFrom && (
                    <div className="invalid-feedback">
                      Weight From should be a number not exceeding 100000.
                    </div>
                  )}
                  {errors.weightTo && (
                    <div className="invalid-feedback">
                      Weight To should be a number not exceeding 100000.
                    </div>
                  )}
                </div>
              </div>


              <div className="col-md-6">
                <div className="form-group">
                  <label htmlFor="volume">Volume</label>
                  <div className="input-group">
                    <input
                      type="number"
                      id="volumeFrom"
                      name="volumeFrom"
                      {...register('volumeFrom')}
                      className={`form-control ${errors.volumeFrom ? 'is-invalid' : ''}`}
                      placeholder="From"
                      step="0.01"
                      max={1000}
                    />
                    <input
                      type="number"
                      id="volumeTo"
                      name="volumeTo"
                      {...register('volumeTo')}
                      className={`form-control ${errors.volumeTo ? 'is-invalid' : ''}`}
                      placeholder="To"
                      step="0.01"
                      max={1000}
                    />
                  </div>
                  {errors.volumeFrom && (
                    <div className="invalid-feedback">
                      Volume From should be a number not exceeding 1000.
                    </div>
                  )}
                  {errors.volumeTo && (
                    <div className="invalid-feedback">
                      Volume To should be a number not exceeding 1000.
                    </div>
                  )}
                </div>
              </div>


              <div className="col-md-6">
                <div className="form-group">
                  <label htmlFor="price">Price</label>
                  <div className="input-group">
                    <input
                      type="number"
                      id="priceFrom"
                      name="priceFrom"
                      {...register('priceFrom')}
                      className={`form-control ${errors.priceFrom ? 'is-invalid' : ''}`}
                      placeholder="From"
                      min={0}
                      step="0.01"
                      max={1000000}
                    />
                    <input
                      type="number"
                      id="priceTo"
                      name="priceTo"
                      {...register('priceTo')}
                      className={`form-control ${errors.priceTo ? 'is-invalid' : ''}`}
                      placeholder="To"
                      min={0}
                      step="0.01"
                      max={1000000}
                    />
                  </div>
                  {errors.priceFrom && (
                    <div className="invalid-feedback">
                      Price From should be a number between 0 and 1,000,000 or leave it empty.
                    </div>
                  )}
                  {errors.priceTo && (
                    <div className="invalid-feedback">
                      Price To should be a number between 0 and 1,000,000 or leave it empty.
                    </div>
                  )}
                </div>
              </div>


              <div className="col-md-6">
                <div className="form-group">
                  <label htmlFor="distance">Distance</label>
                  <div className="input-group">
                    <input
                      type="number"
                      id="distanceFrom"
                      name="distanceFrom"
                      {...register('distanceFrom')}
                      className={`form-control ${errors.distanceFrom ? 'is-invalid' : ''}`}
                      placeholder="From"
                      min={0}
                      step="0.01"
                      max={10000}
                    />
                    <input
                      type="number"
                      id="distanceTo"
                      name="distanceTo"
                      {...register('distanceTo')}
                      className={`form-control ${errors.distanceTo ? 'is-invalid' : ''}`}
                      placeholder="To"
                      min={0}
                      step="0.01"
                      max={10000}
                    />
                  </div>
                  {errors.distanceFrom && (
                    <div className="invalid-feedback">
                      Distance From should be a number between 0 and 10,000 or leave it empty.
                    </div>
                  )}
                  {errors.distanceTo && (
                    <div className="invalid-feedback">
                      Distance To should be a number between 0 and 10,000 or leave it empty.
                    </div>
                  )}
                </div>
              </div>


              <div className="col-md-6">
                <div className="form-group">
                  <label htmlFor="typeOfCargo">Type of cargo</label>
                  <input
                    type="text"
                    id="typeOfCargo"
                    name="typeOfCargo"
                    {...register('typeOfCargo')}
                    className={`form-control ${errors.typeOfCargo ? 'is-invalid' : ''}`}
                  />
                  {errors.typeOfCargo && (
                    <div className="invalid-feedback">
                      Type of Cargo should not exceed 50 characters.
                    </div>
                  )}
                </div>
              </div>



            </div>
            <div className="form-group">
              <button type="submit" className="btn btn-primary cargoBtnColor">
                Search
              </button>
            </div>
          </form>
        </div>
      </div>

      {/*List of cargo*/}
      <div className="card fullwidth marginTB">
        <div className="card-body overflow-body">
          <div>
            <Pagination
              currentPage={cargoscurrentPage}
              totalPages={cargostotalPages}
              handleFirst={handleFirst}
              handlePrev={handlePrev}
              handleNext={handleNext}
              handleLast={handleLast}
            />
          </div>
          {cargos && cargos.cargoList && cargos.cargoList.length > 0 ? (
            <table className="table cargoTable">
              <thead>
                <tr>
                  <th>id</th>
                  <th>Created</th>
                  <th className="wrap-content">Name</th>
                  <th>Price</th>
                  <th>Weight</th>
                  <th>Volume</th>
                  <th>Loading</th>
                  <th >Unloading</th>
                  <th className="wrap-content">Notes</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {cargos.cargoList.map((cargo) => (
                  <tr id={'c_' + cargo.id} key={cargo.id}
                    className={selectedCargoRow === cargo.id ? 'selected' : ''}
                    onClick={() => handleCargoRowClick(cargo.id)}>

                    <td><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-box-seam isonInTable" viewBox="0 0 16 16">
                      <path d="M8.186 1.113a.5.5 0 0 0-.372 0L1.846 3.5l2.404.961L10.404 2l-2.218-.887zm3.564 1.426L5.596 5 8 5.961 14.154 3.5l-2.404-.961zm3.25 1.7-6.5 2.6v7.922l6.5-2.6V4.24zM7.5 14.762V6.838L1 4.239v7.923l6.5 2.6zM7.443.184a1.5 1.5 0 0 1 1.114 0l7.129 2.852A.5.5 0 0 1 16 3.5v8.662a1 1 0 0 1-.629.928l-7.185 2.874a.5.5 0 0 1-.372 0L.63 13.09a1 1 0 0 1-.63-.928V3.5a.5.5 0 0 1 .314-.464L7.443.184z" />
                    </svg>&nbsp;{cargo.id}</td>

                    <td>{formatDate(cargo.created_at)}</td>

                    <td className="wrap-content">{cargo.name}</td>
                    <td className="price-column">{cargo.price}<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-currency-euro isonInTable" viewBox="0 0 16 16">
                      <path d="M4 9.42h1.063C5.4 12.323 7.317 14 10.34 14c.622 0 1.167-.068 1.659-.185v-1.3c-.484.119-1.045.17-1.659.17-2.1 0-3.455-1.198-3.775-3.264h4.017v-.928H6.497v-.936c0-.11 0-.219.008-.329h4.078v-.927H6.618c.388-1.898 1.719-2.985 3.723-2.985.614 0 1.175.05 1.659.177V2.194A6.617 6.617 0 0 0 10.341 2c-2.928 0-4.82 1.569-5.244 4.3H4v.928h1.01v1.265H4v.928z" />
                    </svg></td>

                    <td>{cargo.weight}</td>
                    <td>{cargo.volume}</td>

                    <td className='point-column'>{cargo.loading_place}&nbsp;<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-geo-alt isonInTable" viewBox="0 0 16 16">
                      <path d="M12.166 8.94c-.524 1.062-1.234 2.12-1.96 3.07A31.493 31.493 0 0 1 8 14.58a31.481 31.481 0 0 1-2.206-2.57c-.726-.95-1.436-2.008-1.96-3.07C3.304 7.867 3 6.862 3 6a5 5 0 0 1 10 0c0 .862-.305 1.867-.834 2.94zM8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10z" />
                      <path d="M8 8a2 2 0 1 1 0-4 2 2 0 0 1 0 4zm0 1a3 3 0 1 0 0-6 3 3 0 0 0 0 6z" />
                    </svg></td>
                    <td className='point-column'>{cargo.unloading_place}&nbsp;<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-geo-alt isonInTable" viewBox="0 0 16 16">
                      <path d="M12.166 8.94c-.524 1.062-1.234 2.12-1.96 3.07A31.493 31.493 0 0 1 8 14.58a31.481 31.481 0 0 1-2.206-2.57c-.726-.95-1.436-2.008-1.96-3.07C3.304 7.867 3 6.862 3 6a5 5 0 0 1 10 0c0 .862-.305 1.867-.834 2.94zM8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10z" />
                      <path d="M8 8a2 2 0 1 1 0-4 2 2 0 0 1 0 4zm0 1a3 3 0 1 0 0-6 3 3 0 0 0 0 6z" />
                    </svg></td>

                    <td className="wrap-content">
                      <div>
                        <button className="round-button"
                          onClick={() => handleShowContactInfo(cargo.id)}>
                          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-telephone" viewBox="0 0 16 16">
                            <path d="M3.654 1.328a.678.678 0 0 0-1.015-.063L1.605 2.3c-.483.484-.661 1.169-.45 1.77a17.568 17.568 0 0 0 4.168 6.608 17.569 17.569 0 0 0 6.608 4.168c.601.211 1.286.033 1.77-.45l1.034-1.034a.678.678 0 0 0-.063-1.015l-2.307-1.794a.678.678 0 0 0-.58-.122l-2.19.547a1.745 1.745 0 0 1-1.657-.459L5.482 8.062a1.745 1.745 0 0 1-.46-1.657l.548-2.19a.678.678 0 0 0-.122-.58L3.654 1.328zM1.884.511a1.745 1.745 0 0 1 2.612.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511z" />
                          </svg>
                        </button>
                        <button className="round-button"
                          onClick={() => handleShowContactInfo(cargo.id)}>
                          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-chat-left-text" viewBox="0 0 16 16">
                            <path d="M14 1a1 1 0 0 1 1 1v8a1 1 0 0 1-1 1H4.414A2 2 0 0 0 3 11.586l-2 2V2a1 1 0 0 1 1-1h12zM2 0a2 2 0 0 0-2 2v12.793a.5.5 0 0 0 .854.353l2.853-2.853A1 1 0 0 1 4.414 12H14a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z" />
                            <path d="M3 3.5a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5zM3 6a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9A.5.5 0 0 1 3 6zm0 2.5a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5z" />
                          </svg>
                        </button></div>
                      {cargo.additional_notes}</td>
                    <td>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          ) : (
            <p>No cargos available.</p>
          )}

          {/* Mobile cargo list*/}
          {cargos && cargos.cargoList && cargos.cargoList.length > 0 ? (
            <ul className="mobile-list">
              {cargos.cargoList.map((cargo) => (
                <li key={cargo.id}
                  className={selectedCargoRow === cargo.id ? 'selected' : ''}
                  onClick={() => handleCargoRowClick(cargo.id)}>
                  <div>
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-box-seam isonInTable" viewBox="0 0 16 16">
                      <path d="M8.186 1.113a.5.5 0 0 0-.372 0L1.846 3.5l2.404.961L10.404 2l-2.218-.887zm3.564 1.426L5.596 5 8 5.961 14.154 3.5l-2.404-.961zm3.25 1.7-6.5 2.6v7.922l6.5-2.6V4.24zM7.5 14.762V6.838L1 4.239v7.923l6.5 2.6zM7.443.184a1.5 1.5 0 0 1 1.114 0l7.129 2.852A.5.5 0 0 1 16 3.5v8.662a1 1 0 0 1-.629.928l-7.185 2.874a.5.5 0 0 1-.372 0L.63 13.09a1 1 0 0 1-.63-.928V3.5a.5.5 0 0 1 .314-.464L7.443.184z" />
                    </svg>&nbsp;
                    <strong>id: {cargo.id}</strong>
                  </div>
                  <div><strong>Created: {formatDate(cargo.created_at)}</strong></div>
                  <div><strong>Type of cargo: {cargo.name}</strong></div>
                  <div className='price-column'><strong>Price: {cargo.price} € </strong></div>
                  <div><strong>Weight: {cargo.weight} t</strong></div>
                  <div><strong>Volume: {cargo.volume} m&sup3;</strong></div>

                  <div className="point-column"><strong>Loading place: {cargo.loading_place}{' '}</strong>
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-geo-alt isonInTable" viewBox="0 0 16 16">
                      <path d="M12.166 8.94c-.524 1.062-1.234 2.12-1.96 3.07A31.493 31.493 0 0 1 8 14.58a31.481 31.481 0 0 1-2.206-2.57c-.726-.95-1.436-2.008-1.96-3.07C3.304 7.867 3 6.862 3 6a5 5 0 0 1 10 0c0 .862-.305 1.867-.834 2.94zM8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10z" />
                      <path d="M8 8a2 2 0 1 1 0-4 2 2 0 0 1 0 4zm0 1a3 3 0 1 0 0-6 3 3 0 0 0 0 6z" />
                    </svg>
                  </div>
                  <div className="point-column"><strong>Unloading place: {cargo.unloading_place}{' '}</strong>
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-geo-alt isonInTable" viewBox="0 0 16 16">
                      <path d="M12.166 8.94c-.524 1.062-1.234 2.12-1.96 3.07A31.493 31.493 0 0 1 8 14.58a31.481 31.481 0 0 1-2.206-2.57c-.726-.95-1.436-2.008-1.96-3.07C3.304 7.867 3 6.862 3 6a5 5 0 0 1 10 0c0 .862-.305 1.867-.834 2.94zM8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10z" />
                      <path d="M8 8a2 2 0 1 1 0-4 2 2 0 0 1 0 4zm0 1a3 3 0 1 0 0-6 3 3 0 0 0 0 6z" />
                    </svg>
                  </div>
                  <div className="cargo-notes"><strong>Notes: {cargo.additional_notes} </strong></div>

                  <div className="cargo-actions">
                  <div>
                        <button className="round-button"
                          onClick={() => handleShowContactInfo(cargo.id)}><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" className="bi bi-telephone" viewBox="0 0 16 16">
                            <path d="M3.654 1.328a.678.678 0 0 0-1.015-.063L1.605 2.3c-.483.484-.661 1.169-.45 1.77a17.568 17.568 0 0 0 4.168 6.608 17.569 17.569 0 0 0 6.608 4.168c.601.211 1.286.033 1.77-.45l1.034-1.034a.678.678 0 0 0-.063-1.015l-2.307-1.794a.678.678 0 0 0-.58-.122l-2.19.547a1.745 1.745 0 0 1-1.657-.459L5.482 8.062a1.745 1.745 0 0 1-.46-1.657l.548-2.19a.678.678 0 0 0-.122-.58L3.654 1.328zM1.884.511a1.745 1.745 0 0 1 2.612.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511z" />
                          </svg>
                        </button>
                        <button className="round-button"
                          onClick={() => handleShowContactInfo(cargo.id)}><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" className="bi bi-chat-left-text" viewBox="0 0 16 16">
                            <path d="M14 1a1 1 0 0 1 1 1v8a1 1 0 0 1-1 1H4.414A2 2 0 0 0 3 11.586l-2 2V2a1 1 0 0 1 1-1h12zM2 0a2 2 0 0 0-2 2v12.793a.5.5 0 0 0 .854.353l2.853-2.853A1 1 0 0 1 4.414 12H14a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z" />
                            <path d="M3 3.5a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5zM3 6a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9A.5.5 0 0 1 3 6zm0 2.5a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5z" />
                          </svg>
                        </button></div>
                  </div>
                </li>
              ))}
            </ul>
          ) : (
            <p></p>
          )}
          {/* end mobile cargo list*/}

          {cargos && cargos.loading && <div className="spinner-border spinner-border-sm"></div>}
          {cargos && cargos.error && (
            <div className="text-danger">Error loading cargos: {cargos.error.message}</div>
          )}
        </div>
      </div>
      {/*end list of cargo*/}

    </div></div>
  );
}
