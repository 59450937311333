import React from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux'; // Импортируйте useSelector из React Redux
import { Link } from 'react-router-dom';

import { recoverPassword } from '_store/passwordRecovery.slice';

export { ForgotPassword };

function ForgotPassword() {
  const dispatch = useDispatch();
  const recoveryError = useSelector(state => state.passwordRecovery.recoveryError); // Получение значения recoveryError из Redux
  const recoverySuccess = useSelector(state => state.passwordRecovery.recoverySuccess); // Получение значения recoverySuccess из Redux
  const validationSchema = Yup.object().shape({
    email: Yup.string().email('Invalid email address').required('Email is required')
  });

  const formOptions = { resolver: yupResolver(validationSchema) };
  const { register, handleSubmit, formState } = useForm(formOptions);
  const { errors, isSubmitting } = formState;

  const toggleFormFields = (disabled) => {
    document.getElementById('email').disabled = disabled;
    document.querySelector('button[type="submit"]').disabled = disabled;
  };

  const onSubmit = async (data) => {
    try {
      toggleFormFields(true);
     await dispatch(recoverPassword(data));
    } catch (error) {
      
    } finally {
      toggleFormFields(false);
    }
  };

  return (
    <div className="col-md-6 offset-md-3 mscc">
      <div className="card bz">
        <h4 className="card-header">Password Recovery</h4>
        <div className="card-body">
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className={`form-group ${errors.email && 'has-error'}`}>
              <label htmlFor="email">Email</label>
              <input
                type="email"
                id="email"
                name="email"
                {...register('email')}
                className="form-control"
                required
              />
              {errors.email && (
                <div className="text-danger">{errors.email.message}</div>
              )}
            </div>
            
            {recoveryError && (
              <div className="alert alert-danger">{recoveryError}</div>
            )}

            {recoverySuccess && (
              <div className="alert alert-success">Password reset link has been successfully sent to the provided email</div>
            )}

            <div className="form-group">
            <Link to="/login">Back to login</Link>
            </div>

            <div className="form-group">
              <button
                type="submit"
                className="btn btn-primary"
                disabled={isSubmitting}
              >
                {isSubmitting && (
                  <span className="spinner-border spinner-border-sm mr-1"></span>
                )}
                Recover
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
