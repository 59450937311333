//src/searchtransport/SearchTransport.jsx

import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { ContactInfo } from '_components';
import { authActions, userActions } from '_store';
import { searchTransports } from '_store/transport.slice';
import { resetAddTransportStatus } from '_store/transport.slice';
import { Pagination } from '_components';

import '../index.css';

export function SearchTransport() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchData, setSearchData] = useState({});
  const [showContactInfo, setShowContactInfo] = useState(false);
  const [selectedId, setSelectedId] = useState(0);

  //transport
  const { user: authUser } = useSelector(x => x.auth);
  const logout = () => dispatch(authActions.logout());
  const { transports } = useSelector((x) => x.transport);
  const [selectedTransportRow, setTransportSelectedRow] = useState(null);
  const transportSuccess = useSelector((state) => state.transport.transportSuccess);
  //end transport

  // validation

  const validationSchema = Yup.object().shape({
    typeOfTransport: Yup.string().max(50, 'The "Type of Transport" field should not exceed 50 characters'),
    loadingPlace: Yup.string().max(50, 'The "Loading Place" field should not exceed 50 characters'),
    unloadingPlace: Yup.string().max(50, 'The "Unloading Place" field should not exceed 50 characters'),
  });

  const formOptions = { resolver: yupResolver(validationSchema) };
  const { register, handleSubmit, formState, reset } = useForm(formOptions);
  const { errors, isSubmitting } = formState;

  //pagination transport
  const [transportsLength, setTransportsLength] = useState(0);
  const [transportstotalPages, setTransportsTotalPages] = useState(0);
  const [transportsendIndex, setTransportsEndIndex] = useState(0);
  const [transportscurrentPage, setTransportsCurrentPage] = useState(1);
  const transportsitemsPerPage = 10; // Set the number of items per page here

  const handleFirst = () => {
    setTransportsCurrentPage(1);
    dispatch(searchTransports({ currentPage: 1, itemsPerPage: transportsitemsPerPage, queryData: searchData }));
  };

  const handlePrev = () => {
    setTransportsCurrentPage(transportscurrentPage - 1);
    dispatch(searchTransports({ currentPage: transportscurrentPage - 1, itemsPerPage: transportsitemsPerPage, queryData: searchData }));
  };

  const handleNext = () => {
    setTransportsCurrentPage(transportscurrentPage + 1);
    dispatch(searchTransports({ currentPage: transportscurrentPage + 1, itemsPerPage: transportsitemsPerPage, queryData: searchData }));
  };

  const handleLast = () => {
    if (transportstotalPages) {
      setTransportsCurrentPage(transportstotalPages);
      dispatch(searchTransports({ currentPage: transportstotalPages, itemsPerPage: transportsitemsPerPage, queryData: searchData }));
    }
  };

  useEffect(() => {
    if (transports) {

      //console.log(JSON.stringify(transports));

      const newTransportsLength = transports.total;
      const newTotalPages = Math.ceil(newTransportsLength / transportsitemsPerPage);
      const newstartIndex = (transportscurrentPage - 1) * transportsitemsPerPage;
      const newEndIndex = Math.min(newstartIndex + transportsitemsPerPage, newTransportsLength);
      //const visibleTransports = transports.slice(startIndex, endIndex);



      setTransportsLength(newTransportsLength);
      setTransportsTotalPages(newTotalPages);
      setTransportsEndIndex(newEndIndex);
    }
  }, [transports]);
  //end pagination transport

  //transport list 
  const handleTransportRowClick = (id) => {
    setTransportSelectedRow(id);
  };

  useEffect(() => {

    const emptyQueryData = {
      vehicleType: "",
      currentLocation: "",
      volumeFrom: 0,
      volumeTo: 1000,
      capacityFrom: 0,
      capacityTo: 100000
    };

    //console.log("!!!!!!!!!!!");

    dispatch(searchTransports({ currentPage: transportscurrentPage, itemsPerPage: transportsitemsPerPage, queryData: emptyQueryData }));
    setSearchData(emptyQueryData);

  }, [dispatch]);

  //end transport list

  const closeContactInfo = () => {
    setShowContactInfo(false);
  };

  const handleShowContactInfo = (id) => {
    setSelectedId(id);
    setShowContactInfo(true);
  };

  //common function
  function formatDate(dateString) {
    const date = new Date(dateString);
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear().toString().slice(2);
    const hours = date.getHours().toString().padStart(2, '0');
    const minutes = date.getMinutes().toString().padStart(2, '0');
    return `${day}.${month}.${year} - ${hours}:${minutes}`;
  }
  //end common function

  //Submit 
  const onSubmit = async (data) => {

    try {
      setTransportsCurrentPage(1);
      await dispatch(searchTransports({ currentPage: 1, itemsPerPage: transportsitemsPerPage, queryData: data }));
      setSearchData(data);
    } catch (error) {
    } finally {
    }
  };
  //end submit 

  return (
    <div className="container-fluid">

      {showContactInfo && (
        <div className="overlay">
          <div className="inner-div">
            <ContactInfo closeContactInfo={closeContactInfo} entityType={"transport"} selectedId={selectedId} />
          </div>
        </div>)}
      <div className="adf-c">
        <div className="card search">
          <h4 className="card-header transportColor"><svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" className="bi bi-truck" viewBox="0 0 16 16">
            <path d="M0 3.5A1.5 1.5 0 0 1 1.5 2h9A1.5 1.5 0 0 1 12 3.5V5h1.02a1.5 1.5 0 0 1 1.17.563l1.481 1.85a1.5 1.5 0 0 1 .329.938V10.5a1.5 1.5 0 0 1-1.5 1.5H14a2 2 0 1 1-4 0H5a2 2 0 1 1-3.998-.085A1.5 1.5 0 0 1 0 10.5v-7zm1.294 7.456A1.999 1.999 0 0 1 4.732 11h5.536a2.01 2.01 0 0 1 .732-.732V3.5a.5.5 0 0 0-.5-.5h-9a.5.5 0 0 0-.5.5v7a.5.5 0 0 0 .294.456zM12 10a2 2 0 0 1 1.732 1h.768a.5.5 0 0 0 .5-.5V8.35a.5.5 0 0 0-.11-.312l-1.48-1.85A.5.5 0 0 0 13.02 6H12v4zm-9 1a1 1 0 1 0 0 2 1 1 0 0 0 0-2zm9 0a1 1 0 1 0 0 2 1 1 0 0 0 0-2z" />
          </svg>&nbsp;Search Transport</h4>
          <div className="card-body">
            <form onSubmit={handleSubmit(onSubmit)}>


              <div className="row">

                <div className="col-md-6">
                  <div className="form-group">
                    <label htmlFor="vehicleType">Type of transport</label>
                    <input
                      type="text"
                      id="vehicleType"
                      name="vehicleType"
                      {...register('vehicleType')}
                      className={`form-control ${errors.vehicleType ? 'is-invalid' : ''}`}
                    />
                    {errors.vehicleType && (
                      <div className="invalid-feedback">
                        Type of Transport should not exceed 50 characters.
                      </div>
                    )}
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="form-group">
                    <label htmlFor="currentLocation">Location Place</label>
                    <input
                      type="text"
                      id="currentLocation"
                      name="currentLocation"
                      {...register('currentLocation')}
                      className={`form-control ${errors.currentLocation ? 'is-invalid' : ''}`}
                    />
                    {errors.currentLocation && (
                      <div className="invalid-feedback">
                        Location Place should not exceed 50 characters
                      </div>
                    )}
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="form-group">
                    <label htmlFor="volume">Volume</label>
                    <div className="input-group">
                      <input
                        type="number"
                        id="volumeFrom"
                        name="volumeFrom"
                        {...register('volumeFrom')}
                        className={`form-control ${errors.volumeFrom ? 'is-invalid' : ''}`}
                        placeholder="From"
                        step="0.01"
                        max={1000}
                      />
                      <input
                        type="number"
                        id="volumeTo"
                        name="volumeTo"
                        {...register('volumeTo')}
                        className={`form-control ${errors.volumeTo ? 'is-invalid' : ''}`}
                        placeholder="To"
                        step="0.01"
                        max={1000}
                      />
                    </div>
                    {errors.volumeFrom && (
                      <div className="invalid-feedback">
                        Volume From should be a number not exceeding 1000.
                      </div>
                    )}
                    {errors.volumeTo && (
                      <div className="invalid-feedback">
                        Volume To should be a number not exceeding 1000.
                      </div>
                    )}
                  </div>
                </div>


                <div className="col-md-6">
                  <div className="form-group">
                    <label htmlFor="price">Capacity</label>
                    <div className="input-group">
                      <input
                        type="number"
                        id="capacityFrom"
                        name="capacityFrom"
                        {...register('capacityFrom')}
                        className={`form-control ${errors.capacityFrom ? 'is-invalid' : ''}`}
                        placeholder="From"
                        min={0}
                        step="0.01"
                        max={1000000}
                      />
                      <input
                        type="number"
                        id="capacityTo"
                        name="capacityTo"
                        {...register('capacityTo')}
                        className={`form-control ${errors.capacityTo ? 'is-invalid' : ''}`}
                        placeholder="To"
                        min={0}
                        step="0.01"
                        max={1000000}
                      />
                    </div>
                    {errors.priceFrom && (
                      <div className="invalid-feedback">
                        Capacity From should be a number between 0 and 1,000,000 or leave it empty.
                      </div>
                    )}
                    {errors.priceTo && (
                      <div className="invalid-feedback">
                        Capacity To should be a number between 0 and 1,000,000 or leave it empty.
                      </div>
                    )}
                  </div>
                </div>

              </div>
              <div className="form-group">
                <button type="submit" className="btn btn-primary transportBtnColor">
                  Search
                </button>
              </div>
            </form>
          </div>
        </div>

        {/*List of transport*/}
        <div className="card fullwidth marginTB">
          <div className="card-body overflow-body">
            <div>
              <Pagination
                currentPage={transportscurrentPage}
                totalPages={transportstotalPages}
                handleFirst={handleFirst}
                handlePrev={handlePrev}
                handleNext={handleNext}
                handleLast={handleLast}
              />
            </div>
            {transports && transports.transportList && transports.transportList.length > 0 ? (
              <table className="table transportTable">
                <thead>
                  <tr>
                    <th>id</th>
                    <th>Created</th>
                    <th className="wrap-content">Vehicle Type</th>
                    <th>Capacity</th>
                    <th>Volume</th>
                    <th >Current location</th>
                    <th className="wrap-content">Notes</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {transports.transportList.map((transport) => (
                    <tr id={'t_' + transport.id} key={transport.id}
                      className={selectedTransportRow === transport.id ? 'selected' : ''}
                      onClick={() => handleTransportRowClick(transport.id)}>

                      <td><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-bus-front-fill isonInTable" viewBox="0 0 16 16">
                        <path d="M16 7a1 1 0 0 1-1 1v3.5c0 .818-.393 1.544-1 2v2a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1-.5-.5V14H5v1.5a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1-.5-.5v-2a2.496 2.496 0 0 1-1-2V8a1 1 0 0 1-1-1V5a1 1 0 0 1 1-1V2.64C1 1.452 1.845.408 3.064.268A43.608 43.608 0 0 1 8 0c2.1 0 3.792.136 4.936.268C14.155.408 15 1.452 15 2.64V4a1 1 0 0 1 1 1v2ZM3.552 3.22A43.306 43.306 0 0 1 8 3c1.837 0 3.353.107 4.448.22a.5.5 0 0 0 .104-.994A44.304 44.304 0 0 0 8 2c-1.876 0-3.426.109-4.552.226a.5.5 0 1 0 .104.994ZM8 4c-1.876 0-3.426.109-4.552.226A.5.5 0 0 0 3 4.723v3.554a.5.5 0 0 0 .448.497C4.574 8.891 6.124 9 8 9c1.876 0 3.426-.109 4.552-.226A.5.5 0 0 0 13 8.277V4.723a.5.5 0 0 0-.448-.497A44.304 44.304 0 0 0 8 4Zm-3 7a1 1 0 1 0-2 0 1 1 0 0 0 2 0Zm8 0a1 1 0 1 0-2 0 1 1 0 0 0 2 0Zm-7 0a1 1 0 0 0 1 1h2a1 1 0 1 0 0-2H7a1 1 0 0 0-1 1Z" />
                      </svg>&nbsp;{transport.id}</td>

                      <td>{formatDate(transport.created_at)}</td>

                      <td className="wrap-content">{transport.vehicle_type}</td>

                      <td>{transport.capacity}</td>
                      <td>{transport.volume}</td>

                      <td className='point-column'>{transport.current_location}&nbsp;<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-geo-alt isonInTable" viewBox="0 0 16 16">
                        <path d="M12.166 8.94c-.524 1.062-1.234 2.12-1.96 3.07A31.493 31.493 0 0 1 8 14.58a31.481 31.481 0 0 1-2.206-2.57c-.726-.95-1.436-2.008-1.96-3.07C3.304 7.867 3 6.862 3 6a5 5 0 0 1 10 0c0 .862-.305 1.867-.834 2.94zM8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10z" />
                        <path d="M8 8a2 2 0 1 1 0-4 2 2 0 0 1 0 4zm0 1a3 3 0 1 0 0-6 3 3 0 0 0 0 6z" />
                      </svg></td>

                      <td className="wrap-content">
                        <div>
                          <button className="round-button"
                            onClick={() => handleShowContactInfo(transport.id)}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-telephone" viewBox="0 0 16 16">
                              <path d="M3.654 1.328a.678.678 0 0 0-1.015-.063L1.605 2.3c-.483.484-.661 1.169-.45 1.77a17.568 17.568 0 0 0 4.168 6.608 17.569 17.569 0 0 0 6.608 4.168c.601.211 1.286.033 1.77-.45l1.034-1.034a.678.678 0 0 0-.063-1.015l-2.307-1.794a.678.678 0 0 0-.58-.122l-2.19.547a1.745 1.745 0 0 1-1.657-.459L5.482 8.062a1.745 1.745 0 0 1-.46-1.657l.548-2.19a.678.678 0 0 0-.122-.58L3.654 1.328zM1.884.511a1.745 1.745 0 0 1 2.612.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511z" />
                            </svg>
                          </button>
                          <button className="round-button"
                            onClick={() => handleShowContactInfo(transport.id)}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-chat-left-text" viewBox="0 0 16 16">
                              <path d="M14 1a1 1 0 0 1 1 1v8a1 1 0 0 1-1 1H4.414A2 2 0 0 0 3 11.586l-2 2V2a1 1 0 0 1 1-1h12zM2 0a2 2 0 0 0-2 2v12.793a.5.5 0 0 0 .854.353l2.853-2.853A1 1 0 0 1 4.414 12H14a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z" />
                              <path d="M3 3.5a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5zM3 6a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9A.5.5 0 0 1 3 6zm0 2.5a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5z" />
                            </svg>
                          </button></div>
                        {transport.comments}</td>
                      <td>

                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            ) : (
              <p>No transports available.</p>
            )}

            {/* Mobile Transport list */}
            {transports && transports.transportList && transports.transportList.length > 0 ? (
              <ul className="mobile-list">
                {transports.transportList.map((transport) => (
                  <li
                    key={transport.id}
                    className={selectedTransportRow === transport.id ? 'selected' : ''}
                    onClick={() => handleTransportRowClick(transport.id)}>
                    <div>
                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-bus-front-fill isonInTable" viewBox="0 0 16 16">
                        <path d="M16 7a1 1 0 0 1-1 1v3.5c0 .818-.393 1.544-1 2v2a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1-.5-.5V14H5v1.5a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1-.5-.5v-2a2.496 2.496 0 0 1-1-2V8a1 1 0 0 1-1-1V5a1 1 0 0 1 1-1V2.64C1 1.452 1.845.408 3.064.268A43.608 43.608 0 0 1 8 0c2.1 0 3.792.136 4.936.268C14.155.408 15 1.452 15 2.64V4a1 1 0 0 1 1 1v2ZM3.552 3.22A43.306 43.306 0 0 1 8 3c1.837 0 3.353.107 4.448.22a.5.5 0 0 0 .104-.994A44.304 44.304 0 0 0 8 2c-1.876 0-3.426.109-4.552.226a.5.5 0 1 0 .104.994ZM8 4c-1.876 0-3.426.109-4.552.226A.5.5 0 0 0 3 4.723v3.554a.5.5 0 0 0 .448.497C4.574 8.891 6.124 9 8 9c1.876 0 3.426-.109 4.552-.226A.5.5 0 0 0 13 8.277V4.723a.5.5 0 0 0-.448-.497A44.304 44.304 0 0 0 8 4Zm-3 7a1 1 0 1 0-2 0 1 1 0 0 0 2 0Zm8 0a1 1 0 1 0-2 0 1 1 0 0 0 2 0Zm-7 0a1 1 0 0 0 1 1h2a1 1 0 1 0 0-2H7a1 1 0 0 0-1 1Z" />
                      </svg>&nbsp;
                      <strong>id: {transport.id}</strong>
                    </div>
                    <div>
                      <strong>Created: {formatDate(transport.created_at)}</strong>
                    </div>
                    <div >
                      <strong>Vehicle Type: {transport.vehicle_type}</strong>
                    </div>
                    <div>
                      <strong>Capacity: {transport.capacity} t</strong>
                    </div>
                    <div>
                      <strong>Volume: {transport.volume} m&sup3;</strong>
                    </div>
                    <div className='point-column'>
                      <strong>Current location: {transport.current_location}{' '}</strong>
                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-geo-alt isonInTable" viewBox="0 0 16 16">
                        <path d="M12.166 8.94c-.524 1.062-1.234 2.12-1.96 3.07A31.493 31.493 0 0 1 8 14.58a31.481 31.481 0 0 1-2.206-2.57c-.726-.95-1.436-2.008-1.96-3.07C3.304 7.867 3 6.862 3 6a5 5 0 0 1 10 0c0 .862-.305 1.867-.834 2.94zM8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10z" />
                        <path d="M8 8a2 2 0 1 1 0-4 2 2 0 0 1 0 4zm0 1a3 3 0 1 0 0-6 3 3 0 0 0 0 6z" />
                      </svg>
                    </div>
                    <div >
                      <strong>Notes: {transport.comments}</strong>
                    </div>

                    <div>
                      <button className="round-button"
                        onClick={() => handleShowContactInfo(transport.id)}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" className="bi bi-telephone" viewBox="0 0 16 16">
                          <path d="M3.654 1.328a.678.678 0 0 0-1.015-.063L1.605 2.3c-.483.484-.661 1.169-.45 1.77a17.568 17.568 0 0 0 4.168 6.608 17.569 17.569 0 0 0 6.608 4.168c.601.211 1.286.033 1.77-.45l1.034-1.034a.678.678 0 0 0-.063-1.015l-2.307-1.794a.678.678 0 0 0-.58-.122l-2.19.547a1.745 1.745 0 0 1-1.657-.459L5.482 8.062a1.745 1.745 0 0 1-.46-1.657l.548-2.19a.678.678 0 0 0-.122-.58L3.654 1.328zM1.884.511a1.745 1.745 0 0 1 2.612.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511z" />
                        </svg>
                      </button>
                      <button className="round-button"
                        onClick={() => handleShowContactInfo(transport.id)}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" className="bi bi-chat-left-text" viewBox="0 0 16 16">
                          <path d="M14 1a1 1 0 0 1 1 1v8a1 1 0 0 1-1 1H4.414A2 2 0 0 0 3 11.586l-2 2V2a1 1 0 0 1 1-1h12zM2 0a2 2 0 0 0-2 2v12.793a.5.5 0 0 0 .854.353l2.853-2.853A1 1 0 0 1 4.414 12H14a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z" />
                          <path d="M3 3.5a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5zM3 6a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9A.5.5 0 0 1 3 6zm0 2.5a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5z" />
                        </svg>
                      </button></div>

                  </li>
                ))}
              </ul>
            ) : (
              <p></p>
            )}
            {/*end mobile transport list */}

            {transports && transports.loading && <div className="spinner-border spinner-border-sm"></div>}
            {transports && transports.error && (
              <div className="text-danger">Error loading transports: {transports.error.message}</div>
            )}
          </div>
        </div>
        {/*end list of transport*/}

      </div></div>
  );
}
