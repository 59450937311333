//App.js
import { useState, useEffect } from 'react';
import { Routes, Route, Navigate, useNavigate, useLocation } from 'react-router-dom';
import { history } from '_helpers';
import { MyNav, PrivateRoute } from '_components';
import { Home } from 'home'; // Импортируйте Home из home
import { Login } from 'login';
import { Registration } from 'registration';
import { ForgotPassword } from 'forgotpassword';
import { AddCargo } from 'addcargo';
import { AddTransport } from 'addtransport';
import { SearchCargo } from 'searchcargo';
import { SearchTransport } from 'searchtransport';
import { Dashboard } from 'dashboard';
 

export { App };

function App() {
    history.navigate = useNavigate();
    history.location = useLocation();

    const [appLoaded, setAppLoaded] = useState(false);

    useEffect(() => {
        setTimeout(() => {
            setAppLoaded(true);  
        }, 2000);  
    }, []);

    if (!appLoaded) {
        // Пока приложение не загрузилось, отображаем лоадер или другой индикатор загрузки.
        return (
            <div className="app-loader">
                <div className="spinner">
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                </div>
            </div>
        );
    }

    return (
        <div className="app-container bg-light">
            <MyNav />
            <div className="container pt-4 pb-4">
                <Routes>
                    <Route path="/login" element={<Login />} />
                    <Route path="/register" element={<Registration />} />
                    <Route path="/forgot-password" element={<ForgotPassword />} />
                    <Route
                        path="/"
                        element={
                            <Home />
                        }
                    />
                    <Route
                        path="/addcargo"
                        element={
                            <PrivateRoute>
                                <AddCargo />
                            </PrivateRoute>
                        }
                    />
                    <Route
                        path="/addtransport"
                        element={
                            <PrivateRoute>
                                <AddTransport />
                            </PrivateRoute>
                        }
                    />
                    <Route
                        path="/searchtransport"
                        element={
                            <PrivateRoute>
                                <SearchTransport />
                            </PrivateRoute>
                        }
                    />
                    <Route
                        path="/searchcargo"
                        element={
                            <PrivateRoute>
                                <SearchCargo />
                            </PrivateRoute>
                        }
                    />
                    <Route
                        path="/dashboard"
                        element={
                            <PrivateRoute>
                                <Dashboard />
                            </PrivateRoute>
                        }
                    />
                    <Route path="*" element={<Navigate to="/" />} />
                </Routes>
            </div>
                <footer>
                    <div id="sun"></div>
                    <div id="hills"></div>
                    <div id="trees"></div>
                    <div className="text-center mt-4 terms">
                        <a href="/doc/terms.html">Terms and Conditions</a> | 
                        <a href="/doc/gdpr.html">GDPR</a> | 
                        <a href="/doc/privacy.html">Privacy Policy</a> |
                        <a href="/">© 2024 DriveCargo. All rights reserved.</a>
                    </div>
                </footer>
        </div>
    );
}
