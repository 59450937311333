// AddCargo.jsx
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { addCargo, updateCargo } from '_store/cargo.slice';
import { resetAddCargoStatus } from '_store/cargo.slice';
import { LocationPicker } from '_components';
import { fetchCargoById } from '_store/cargo.slice';
import { CommunicationSettingsModal } from '_components';
import {fetchCommunicationSettings} from '_store/persone.slice';
import '../index.css';

export function AddCargo({ isUpdate, closeUpdateCargo, cargoId }) {
  const dispatch = useDispatch();
  const cargoError = useSelector((state) => state.cargo.cargoError);
  const cargoSuccess = useSelector((state) => state.cargo.cargoSuccess);
  const navigate = useNavigate();
  const [isMapModalOpen, setIsMapModalOpen] = useState(false);
  const [selectedPointACoordinates, setSelectedPointACoordinates] = useState(null);
  const [selectedPointBCoordinates, setSelectedPointBCoordinates] = useState(null);
  const [locationPointAError, setLocationPointAError] = useState('');
  const [locationPointBError, setLocationPointBError] = useState('');
  const cargoData = useSelector((state) => state.cargo.cargoData);
  const [showCommunicationSettings, setShowCommunicationSettings] = useState(false);
  const personeError = useSelector((state) => state.persone.personeError);
  const personeData = useSelector((state) => state.persone.personeData);
  const [selectedCoordinates, setSelectedCoordinates] = useState(null);
  const adding = useSelector((state)=>state.cargo.adding);
  const [isInProgress, setisInProgress] = useState(false);

  const handleCancelClick = () => {
    closeUpdateCargo();
  };


  const validationSchema = Yup.object().shape({
    name: Yup.string()
      .max(50, 'Name should not exceed 50 characters')
      .required('Name is required'),

    additionalNotes: Yup.string()
      .max(250, 'Additional notes should not exceed 250 characters')
  });

  const formOptions = { resolver: yupResolver(validationSchema) };
  const { register, handleSubmit, formState, reset } = useForm(formOptions);
  const { errors, isSubmitting } = formState;
  const location = useLocation();


  useEffect(() => {
    if (adding) {
      setisInProgress(true);
    }
   else{
    setisInProgress(false);
   }
  }, [adding]);

  useEffect(() => {
    if (!personeData) {
      dispatch(fetchCommunicationSettings())
        .then((result) => {

        })
        .catch((error) => {

        });
    }
  }, [dispatch]);

  const handleLocationPointASelected = (coordinates) => {
    if (coordinates) {
      setSelectedPointACoordinates(coordinates);
      setLocationPointAError('');
    }
    else {
      setLocationPointAError('Please select unloading location.');
    }
  };

  const handleLocationPointBSelected = (coordinates) => {
    if (coordinates) {
      setSelectedPointBCoordinates(coordinates);
      setLocationPointBError('');
    }
    else {
      setLocationPointAError('Please select unloading location.');
    }
  };

  useEffect(() => {
    if (isUpdate && cargoId) {
      dispatch(fetchCargoById(cargoId));
    }
  }, [isUpdate, cargoId]);

  useEffect(() => {

    if (isUpdate && cargoData) {

      reset({
        name: cargoData.name,
        weight: cargoData.weight,
        volume: cargoData.volume,
        price: cargoData.price,
        additionalNotes: cargoData.additional_notes
      });

      setSelectedPointACoordinates({
        lng: cargoData.point_a_longitude,
        lat: cargoData.point_a_latitude,
      });

      setSelectedPointBCoordinates({
        lng: cargoData.point_b_longitude,
        lat: cargoData.point_b_latitude,
      });
    }
  }, [isUpdate, cargoData]);

  useEffect(() => {
    if (cargoError) {
      toggleFormFields(false);
    }
  }, [cargoError]);

  useEffect(() => {
    if (cargoSuccess) {
      startTimer();
    }
    else {
    }
  }, [cargoSuccess]);

  useEffect(() => {
    dispatch(resetAddCargoStatus());//clear status

  }, [location.pathname]);

  useEffect(() => {
    if (personeError) {
      setShowCommunicationSettings(true);
    }
  }, [personeError]);

  const toggleFormFields = (disabled) => {
    const submitButton = document.querySelector('button[type="submit"]');
    if (submitButton) {
      submitButton.disabled = disabled;
    }

    const cancelButton = document.querySelector('button.cancelBtn[type="button"]');
    if (cancelButton) {
      cancelButton.disabled = disabled;
    }
  };

  const startTimer = () => {
    const timer = setTimeout(() => {
      toggleFormFields(false);
      dispatch(resetAddCargoStatus());//clear status
      if (!isUpdate) {
        //navigate('/');
      }
    }, 1000);

    return () => clearTimeout(timer);
  };

  const onSubmit = async (data) => {

    try {

      if (!selectedPointACoordinates || !selectedPointBCoordinates) {
        if (!selectedPointACoordinates) {
          setLocationPointAError('Please select loading location.');
        }
        if (!selectedPointBCoordinates) {
          setLocationPointBError('Please select unloading location.');
        }
        return;
      }

      data.point_a_longitude = selectedPointACoordinates.lng;
      data.point_a_latitude = selectedPointACoordinates.lat
      data.point_b_longitude = selectedPointBCoordinates.lng;
      data.point_b_latitude = selectedPointBCoordinates.lat

      toggleFormFields(true);

      if (isUpdate) {
        await dispatch(updateCargo({ cargoId: cargoId, data: data }));

      }
      else {
        await dispatch(addCargo(data));
      }

    } catch (error) {
      //console.log("error:" + error);
    } finally {

    }
  };

  const [selectedPointType, setPointType] = useState('');
  const handleLocationPoint = selectedPointType === 'pointA' ? handleLocationPointASelected : handleLocationPointBSelected;

  //map
  const openMapModal = (pointType,coordinates) => {
    setSelectedCoordinates(coordinates);
    setPointType(pointType);
    setIsMapModalOpen(true);
  };

  const closeMapModal = () => {
    setIsMapModalOpen(false);
  };
  //end map

  const closeCommunicationSettings = () => {
    setShowCommunicationSettings(false);
  };

  return (

    <div className="container-fluid">

      {showCommunicationSettings && (
        <div className="overlay">
          <div className="inner-div">
            <CommunicationSettingsModal closeDialog={closeCommunicationSettings} />
          </div>
        </div>)}

      <div className={isUpdate ? "updateCargo" : "adf-c"}>
        <div className="card">
          <h4 className="card-header cargoHeader"><svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" className="bi bi-box-seam isonHeader" viewBox="0 0 16 16">
            <path d="M8.186 1.113a.5.5 0 0 0-.372 0L1.846 3.5l2.404.961L10.404 2l-2.218-.887zm3.564 1.426L5.596 5 8 5.961 14.154 3.5l-2.404-.961zm3.25 1.7-6.5 2.6v7.922l6.5-2.6V4.24zM7.5 14.762V6.838L1 4.239v7.923l6.5 2.6zM7.443.184a1.5 1.5 0 0 1 1.114 0l7.129 2.852A.5.5 0 0 1 16 3.5v8.662a1 1 0 0 1-.629.928l-7.185 2.874a.5.5 0 0 1-.372 0L.63 13.09a1 1 0 0 1-.63-.928V3.5a.5.5 0 0 1 .314-.464L7.443.184z" />
          </svg>&nbsp;  {isUpdate ? 'Update Cargo' : 'Add Cargo'}</h4>
          <div className="card-body">
            <form onSubmit={handleSubmit(onSubmit)}>

              <div className="row rowcargo">
                <div className={`form-group w48perc  ${errors.name && 'has-error'}`}>
                  <label htmlFor="name">Type of cargo</label>
                  <input
                    type="text"
                    id="name"
                    name="name"
                    {...register('name')}
                    className="form-control"
                    required
                  />
                  {errors.name && (
                    <div className="text-danger">{errors.name.message}</div>
                  )}
                </div>

                <div className={`form-group w48perc  ${errors.price && 'has-error'}`}>
                  <label htmlFor="price">Price (€)</label>
                  <input
                    type="number"
                    id="price"
                    step="0.01"
                    name="price"
                    {...register('price')}
                    className="form-control"
                    required
                  />
                  {errors.price && (
                    <div className="text-danger">{errors.price.message}</div>
                  )}
                </div>

                {/*  Weight */}
                <div className={`form-group w48perc  ${errors.weight && 'has-error'}`}>
                  <label htmlFor="weight">Weight (tonnes)</label>
                  <input
                    type="number"
                    step="0.01" // Устанавливаем шаг в 0.01 для десятичных значений
                    id="weight"
                    name="weight"
                    {...register('weight')}
                    className="form-control"
                    required
                  />
                  {errors.weight && (
                    <div className="text-danger">{errors.weight.message}</div>
                  )}
                </div>

                {/*  volume */}
                <div className={`form-group w48perc  ${errors.volume && 'has-error'}`}>
                  <label htmlFor="volume">Volume (Cubic Meters)</label>
                  <input
                    type="number"
                    step="0.01" // Устанавливаем шаг в 0.01 для десятичных значений
                    id="volume"
                    name="volume"
                    {...register('volume')}
                    className="form-control"
                    required
                  />
                  {errors.volume && (
                    <div className="text-danger">{errors.volume.message}</div>
                  )}
                </div>
              </div>

              {/* Location point A */}
              <div className={`form-group ${errors.current_location || locationPointAError ? 'has-error' : ''}`}>
                <div>
                  <label htmlFor="current_location">Loading location:</label>&nbsp;&nbsp;&nbsp;&nbsp;
                  <button className='btn btn-primary btnSetLocation cargoBtnColor' type="button" onClick={() => openMapModal('pointA',selectedPointACoordinates)}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-geo-alt-fill" viewBox="0 0 16 16">
                      <path d="M8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10zm0-7a3 3 0 1 1 0-6 3 3 0 0 1 0 6z" />
                    </svg>
                  </button>
                </div>

                {isMapModalOpen ? (
                  <div className='locationPicker-container'><LocationPicker onLocationSelected={handleLocationPoint} closeMapModal={closeMapModal} coordinates={selectedCoordinates}/></div>
                ) : null}

                {/* Отобразите выбранные координаты, если они есть */}
                {selectedPointACoordinates && (
                  <div>
                    <div>Selected Coordinates:</div>
                    <div>Latitude: {selectedPointACoordinates.lat}</div>
                    <div>Longitude: {selectedPointACoordinates.lng}</div>
                  </div>
                )}

                {/* Ошибка, если не выбрано местоположение */}
                {errors.current_location || locationPointAError ? (
                  <div className="text-danger">
                    {errors.current_location ? errors.current_location.message : locationPointAError}
                  </div>
                ) : null}
              </div>
              {/* end location point a */}

              {/* Location point B */}
              <div className={`form-group ${errors.current_location || locationPointBError ? 'has-error' : ''}`}>
                <div>
                  <label htmlFor="current_location">Unloading location:</label>
                  <button className='btn btn-primary btnSetLocation cargoBtnColor' type="button" onClick={() => openMapModal('pointB',selectedPointBCoordinates)}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-geo-alt-fill" viewBox="0 0 16 16">
                      <path d="M8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10zm0-7a3 3 0 1 1 0-6 3 3 0 0 1 0 6z" />
                    </svg>
                  </button>
                </div>

                {/* Отобразите выбранные координаты, если они есть */}
                {selectedPointBCoordinates && (
                  <div>
                    <div>Selected Coordinates:</div>
                    <div>Latitude: {selectedPointBCoordinates.lat}</div>
                    <div>Longitude: {selectedPointBCoordinates.lng}</div>
                  </div>
                )}

                {/* Ошибка, если не выбрано местоположение */}
                {errors.current_location || locationPointBError ? (
                  <div className="text-danger">
                    {errors.current_location ? errors.current_location.message : locationPointBError}
                  </div>
                ) : null}
              </div>
              {/* end location point B*/}

              <div className={`form-group ${errors.additionalNotes && 'has-error'}`}>
                <label htmlFor="additionalNotes">Additional Notes</label>
                <textarea
                  type="text"
                  id="additionalNotes"
                  name="additionalNotes"
                  {...register('additionalNotes')}
                  className="form-control"
                  rows="3"
                  style={{ resize: "none" }}
                />
                {errors.additionalNotes && (
                  <div className="text-danger">{errors.additionalNotes.message}</div>
                )}
              </div>

              {cargoError && (
                <div className="alert alert-danger">{cargoError}</div>
              )}

              {isInProgress && (
                <div className="alert alert-success">{isUpdate ? 'Cargo updating in process ...' : 'Cargo adding in process ...'}</div>
              )}

              {cargoSuccess && (
                <div className="alert alert-success">{isUpdate ? 'Cargo updated successfully' : 'Cargo added successfully'}</div>
              )}

              <div className="form-group btngroup">
                <button type="submit" className="btn btn-primary marginRight cargoBtnColor">
                  {isUpdate ? 'Update Cargo' : 'Add Cargo'}
                </button>

                {isUpdate && (
                  <button type="button" className="btn btn-primary cancelBtn cargoBtnColor"
                    onClick={handleCancelClick}>
                    Cancel
                  </button>
                )}
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

